/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from './NumberInput';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';

const DividerHeight = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const { handleDividerHeightRequest } = useDataManager();

  return (
    <div className="divider-container">
      {projectData.dividerCheck && (
        <NumberInput
          name="divider_height"
          label={t('parameters.divider_height')}
          defaultValue={projectData.dividerHeight}
          handleOnBlur={handleDividerHeightRequest}
        />
      )}
    </div>
  );
};

export default DividerHeight;
