/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from './NumberInput';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';

const SideAngle = ({ index }) => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const { handleSideAngleRequest } = useDataManager();

  return (
    <NumberInput
      name={`side_${index + 1}_angle`}
      disabled
      label={t('parameters.side_angle', { a1: index + 1, a2: index + 2 })}
      defaultValue={
        projectData.sides !== null ? projectData.sides[index].angle : null
      }
      handleOnBlur={(value) => handleSideAngleRequest(value, index)}
    />
  );
};

export default SideAngle;
