import React from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from './providers/ProjectDataProvider/use';
import ProductSelection from './views/ProductSelection';
import Configurator from './views/Configurator';
import NavigationButtons from './views/NavigationButtons';
import AdditionalNotes from './views/AdditionalNotes';
import CustomerInfo from './views/CustomerInfo';
import Summary from './views/Summary';
import Login from './components/Login';
import {
  SECTION_PRODUCT_SELECTION,
  SECTION_TEMPLATE_SELECTION,
  SECTION_PARAMETERS,
  SECTION_ADDITIONAL_NOTES,
  SECTION_CUSTOMER_INFO,
  SECTION_SUMMARY,
} from './constants/sections';

import './styles/main.css';

import './styles/navigation_buttons.css';
import { EXTERNAL_USER } from './constants/userTypes';

const App = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const handleHomeButton = () => {
    window.location.reload();   
  };
  return (
    <>
      <header>
        <div className="header-container">
          <div className="app-title font-title">
            {projectData.currentSection !== SECTION_PRODUCT_SELECTION &&
              (
                <button type='button' onClick={handleHomeButton}>
                  <img 
                    src="/images/Home_button_normal.svg"
                    alt="HOME"
                  />
                </button>
              )}
            {t('product_configurator')}
          </div>
          {(projectData.currentSection === SECTION_PRODUCT_SELECTION || projectData.userType !== EXTERNAL_USER) && 
            <Login />
          }
        </div>
        {projectData.currentSection !== SECTION_PRODUCT_SELECTION && 
        (
        <div className="header-container">
          <p className="pname-header">
            {projectData.currentProduct
              ? t(`product_selection.${projectData.currentProduct.name}`)
              : ''}
          </p>          
        </div>
        ) }
        {t(`parameter_description`).length > 0 &&
        (
        <p>
          {projectData.currentSection === SECTION_PARAMETERS &&
            t(`parameter_description`)}
        </p>
        ) }
      </header>

      {(projectData.currentSection !== SECTION_PRODUCT_SELECTION) && 
        (
          <>
            <div className='dump-left-container'>
              <div className="dump-left-space" />
              <NavigationButtons />
            </div>
          </>          
        )
      }

      {projectData.currentSection === SECTION_PRODUCT_SELECTION && (
        <ProductSelection />
      )}
      {(projectData.currentSection === SECTION_TEMPLATE_SELECTION ||
        projectData.currentSection === SECTION_PARAMETERS) && <Configurator />}
      {projectData.currentSection === SECTION_ADDITIONAL_NOTES && (
        <AdditionalNotes />
      )}
      {projectData.currentSection === SECTION_CUSTOMER_INFO && <CustomerInfo />}
      {projectData.currentSection === SECTION_SUMMARY && <Summary />}
      
    </>
  );
};

export default App;