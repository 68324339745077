import React, { useMemo } from 'react';
import { filterGlasses } from '../constants/glassOption';
import useProjectData from '../providers/ProjectDataProvider/use';
import useDataManager from '../providers/DataManagerProvider/use';

const useGlass = (type) => {
  const { projectData } = useProjectData();
  const { handleGlassRequest } = useDataManager();
  const glassList = useMemo(() => {
    if (projectData.fullGlasses === null) return null;
    const glasses = filterGlasses(projectData.fullGlasses, projectData, projectData.dividerCheck)

    return glasses.map((glass) => ({
      value: glass.value,
      label: <div>{glass.label.replace(/\d+mm/, '')}</div>,
      name: glass.label.replace(/\d+mm/, '')
    }));
  }, [projectData.fullGlasses, projectData.openingHeight, projectData.dividerCheck]);

  const chosenGlass = useMemo(() => {
    const productId = projectData.currentProduct.id;
    let currentGlass = projectData.glass;
    if (type === 'lower_glass') currentGlass = projectData.lowerGlass;
    if (type === 'upper_glass') currentGlass = projectData.upperGlass;
    let glass = glassList.find(
      (glass) => parseInt(glass.value) === currentGlass
    );
    if (!glass) {
      glass = projectData.fullGlasses.find(
        (glass) => parseInt(glass.value) === currentGlass
      );
      if (!glass)
      {
        let defaultGlass = 0;
        switch (productId) {
          case 'PL':
            defaultGlass = 8331;
            break;
          case 'TL':
            defaultGlass = 6520;
            break;
          case 'LD':
            defaultGlass = 6509;
            break;
          default:
            break;
        }
        glass = projectData.fullGlasses.find(
          (glass) => parseInt(glass.value) === defaultGlass
        );
      }
      glass = glassList.find((g) => g.name === glass.label.replace(/\d+mm/, ''));
    }
    if(parseInt(currentGlass) !== parseInt(glass.value)) {
      console.log("different glass", projectData.glass, glass.value);
      handleGlassRequest(glass, {name: type, centinel: 1});
    }
    return glass;
  }, [
    glassList,
    type,
    projectData.glass,
    projectData.lowerGlass,
    projectData.upperGlass,
    projectData.currentProduct.id,
  ]);
  return [glassList, chosenGlass];
};

export default useGlass;
