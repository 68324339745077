/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import useProjectData from '../../providers/ProjectDataProvider/use';
import Products from '../../data/Products';
import {
  SECTION_TEMPLATE_SELECTION,
  SECTION_PARAMETERS
} from '../../constants/sections';

const Product = ({ productId, productName, description, productDisabled }) => {

  const { projectData, dispatchProjectData } = useProjectData();
  const product = Products.find((product) => product.id === productId);
  const selectProduct = () => {
    if( productDisabled ) {
      return;
    }
    if (
      projectData.currentProduct !== null &&
      projectData.currentProduct.id === product.id
    )
      return;

    dispatchProjectData({ type: 'current_product', payload: product });
    const check = () => {
      setTimeout( () => {
        if (product === null) {
          check();
        } else {
          projectData.currentProduct = product;
          dispatchProjectData({ type: 'current_section', payload: projectData.currentProduct.hasTemplateSelection
          ? SECTION_TEMPLATE_SELECTION
          : SECTION_PARAMETERS });
        }
      }, 500);
    };
    check();
  };
  const productIsSelected =
    projectData.currentProduct !== null &&
    productId === projectData.currentProduct.id;

  return (
    <div onClick={selectProduct} className="product">
      {productDisabled && (
        <div className="product-disabled" />  
      )}
      <div className={`image-container ${productDisabled ? 'image-disabled' : ''}`}>
        <img
          className="product-image"
          src={`/images/${product.imageName}`}
          alt={product.alt}
        />
        <div className={`filter ${productIsSelected ? 'blue-filter' : ''}`} />
      </div>
      <div
        className={`${
          productIsSelected ? 'selected-product' : 'unselected-product'
        }`}
      >
        {productName}
      </div>
      <div className="product-description">
        {description}
      </div>
    </div>    
  );
};

export default Product;
