/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';
import ParametersService from '../../services/ParametersService';
import SwitchParameter from './SwitchParameter';
import useDataManager from '../../providers/DataManagerProvider/use';
import './additional_notes.css';
import { DISTRIBUTOR_USER } from '../../constants/userTypes';
import ImageUpload from '../../components/ImageUpload';

const AdditionalNotes = () => {
  const { projectData, dispatchProjectData } = useProjectData();
  const { handleAdditionalNotesChange } = useDataManager();
  const [inputRef] = useState(React.createRef());
  const { t } = useTranslation('common');

  const distributorParameters = ParametersService.getDistributorParameters(projectData.userType, projectData.currentProduct.name);
  const distributorParametersComponents = distributorParameters.map(
    (parameter) => (
      <SwitchParameter
        name={parameter.name}
        camelCaseName={parameter.camelCaseName}

      />
    )
  );

  useEffect(() => {
    const textArea = inputRef.current;
    distributorParameters.forEach(param => dispatchProjectData({ type: param.name, payload: false}));

    return () => handleAdditionalNotesChange(textArea.value);
  }, []);

  useEffect(() => {
    distributorParameters.forEach(param => dispatchProjectData({ type: param.name, payload: false}));
  }, [projectData.userType])


  const handleUserComments = (e) => {
    dispatchProjectData({type: 'additional_notes', payload: e.target.value})
  }

  return (
    <div className="additional-notes-container">
      {projectData.userType === DISTRIBUTOR_USER && distributorParameters && (
        <div className="distributor-parameters">
          {distributorParametersComponents}
        </div>
      )}

      <div className='additional-notes--comments'>
        <div className="additional-notes">{t('additional_notes')}</div>
        <div className="additional-notes-description">
          {t('additional_notes_description')}
        </div>
        <textarea
          onChange={handleUserComments}
          ref={inputRef}
          name="additional-notes"
          className="notes-area"
          id="notes-area"
          rows="9"
          defaultValue={projectData.additionalNotes}
        />
      </div>
      <div className='break' />
      <ImageUpload />
    </div>
  );
};

export default AdditionalNotes;
