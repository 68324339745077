/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';

const TemplateSelection = () => {
  const { t } = useTranslation('common');
  const { projectData, dispatchProjectData } = useProjectData();
  const handleTemplateSelection = (templateIndex) => {
    const template = projectData.currentProduct.templates[templateIndex];
    if (template.name !== projectData.currentTemplate.name)
      dispatchProjectData({ type: 'current_template', payload: template });
  };

  const templates = projectData.currentProduct.templates.map(
    (template, index) => (
      <div
        onClick={() => handleTemplateSelection(index)}
        key={template.name}
        className={`template-item ${
          template.name === projectData.currentTemplate.name
            ? 'selected-template'
            : 'unselected-template'
        }`}
      >
        <div>{t(`templates.${template.name}`)}</div>
        <img
          style={{
            display:
              template.name === projectData.currentTemplate.name
                ? 'none'
                : 'initial',
          }}
          className="template-image"
          src={`/images/templates/${template.img}.svg`}
          alt={template.name}
        />
        <img
          style={{
            display:
              template.name === projectData.currentTemplate.name
                ? 'initial'
                : 'none',
          }}
          className="template-image"
          src={`/images/templates/${template.img}_active.svg`}
          alt={template.name}
        />
      </div>
    )
  );

  return <div className="templates-container">{templates}</div>;
};
export default TemplateSelection;
