/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { isEmpty } from 'lodash';
import DataManagerContext from './context';
import useProjectData from '../ProjectDataProvider/use';
import RestService from '../../services/RestService';
import { getCost, updateDrawing } from '../../api';
import TokenRestService from '../../services/TokenRestService';
import { 
  LD_MAX_WIDTH, PL_MAX_WIDTH, TL_MAX_WIDTH, LD_MIN_WIDTH, PL_MIN_WIDTH, TL_MIN_WIDTH,
  LD_MAX_HEIGHT, PL_MAX_HEIGHT, TL_MAX_HEIGHT, LD_MIN_HEIGHT, PL_MIN_HEIGHT, TL_MIN_HEIGHT
} from '../../constants/validations';
import { DISTRIBUTOR_USER } from '../../constants/userTypes';

let requestsCounter = 0;
let costRequestsCounter = 0;
const requestQueue = [];

const PRODUCT_UPDATE_PATH = 'productDefinitions/updateMultiple';
const SIDE_UPDATE_PATH = 'sides/updateMultiple';

const resolveService = (userData) => {
  if (userData && JSON.stringify(userData) !== JSON.stringify({})) return TokenRestService;
  return RestService;
}

const DataManagerProvider = ({ children }) => {
  const { projectData, dispatchProjectData } = useProjectData();

  const checkModelLoadingState = () => {
    if (requestsCounter === 0 && isEmpty(requestQueue)) {
      dispatchProjectData({ type: 'loading_model', payload: false });
      makeCostRequest();
    }
  };

  const checkCostLoadingState = () => {
    if (
      requestsCounter === 0 &&
      costRequestsCounter === 0 &&
      isEmpty(requestQueue)
    ) {
      dispatchProjectData({ type: 'loading_cost', payload: false });
    }
  };

  const checkRequestsQueue = () => {
    if (!isEmpty(requestQueue)) {
      const { path, data } = requestQueue.shift();
      makeUpdateRequestCall(path, data);
    }
  };

  const makeCostRequest = () => {
    costRequestsCounter += 1;
    getCost(projectData.projectId, projectData.userData)
      .then((data) => {
        const { price, priceWithVat } = data;
        const currentPrice =
          projectData.userType === DISTRIBUTOR_USER ? price : priceWithVat;
        dispatchProjectData({
          type: 'project_cost',
          payload: parseFloat(currentPrice),
        });
        costRequestsCounter -= 1;
        checkCostLoadingState();
      })
      .catch((error) => {
        console.error(error);
        costRequestsCounter -= 1;
        checkCostLoadingState();
        if (error.status === 403) {
          dispatchProjectData({type: 'remove_user_data'});
          window.location.reload();
        }
      });
  };

  const makeUpdateRequest = (path, data) => {
    if (requestsCounter === 0) {
      makeUpdateRequestCall(path, data);
    } else {
      requestQueue.push({ path, data });
    }
  };

  const makeUpdateRequestCall = (path, data) => {
    requestsCounter += 1;
    dispatchProjectData({ type: 'loading_model', payload: true });
    dispatchProjectData({ type: 'loading_cost', payload: true });
    const requestService = resolveService(projectData.userData);
    return requestService.request('PUT', path, data, projectData.userData)
      .then((data) => {
        requestsCounter -= 1;
        checkModelLoadingState();
        checkRequestsQueue();
      })
      .catch((error) => {
        console.error(error);
        requestsCounter -= 1;
        checkModelLoadingState();
        checkRequestsQueue();
      });
  };

  const handleUpdate3DModel = () => {
    dispatchProjectData({ type: 'loading_model', payload: true });
    setTimeout(() => {
      dispatchProjectData({ type: 'loading_model', payload: false });
    }, 200);
  };

  const handleDividerCheckRequest = (newValue) => {
    if (newValue === projectData.dividerCheck) return;
    const productId = projectData.currentProduct.id;
    let defaultGlass = 0;
    switch (productId) {
      case 'PL':
        defaultGlass = 8331;
        break;
      case 'TL':
        defaultGlass = 6520;
        break;
      case 'LD':
        defaultGlass = 6509;
        break;
      default:
        break;
    }
    const parameter = {
      hole_split: newValue,
      lower_glass: projectData.lowerGlass !== null ? projectData.lowerGlass : defaultGlass,
      upper_glass: projectData.upperGlass !== null ? projectData.upperGlass : defaultGlass,
    };
    const queryData = [];
    projectData.sides.forEach((side) => {
      queryData.push({
        type: projectData.currentProduct.cieloId,
        id: side.productId,
        parameters: { ...parameter },
      });
    });

    makeUpdateRequest(PRODUCT_UPDATE_PATH, queryData);
    dispatchProjectData({ type: 'divider_check', payload: newValue });

    if(!newValue) {
      handleGlassRequest({value: projectData.glass}, {name: 'glass', centinel: 1});
    }
  };

  const handleDividerHeightRequest = (newHeight) => {
    if (newHeight === projectData.dividerHeight) return;
    const parameter = { hole_split_offset: newHeight };
    const queryData = [];
    projectData.sides.forEach((side) => {
      queryData.push({
        type: projectData.currentProduct.cieloId,
        id: side.productId,
        parameters: { ...parameter },
      });
    });

    makeUpdateRequest(PRODUCT_UPDATE_PATH, queryData);
    dispatchProjectData({ type: 'divider_height', payload: newHeight });
  };

  const calculateHeightError = (newHeight, productId) => {
    let error = false;
    switch (productId){
      case 'PL':
        if (newHeight > PL_MAX_HEIGHT || newHeight < PL_MIN_HEIGHT) 
          error = true;
        break;
      case 'LD':
        if (newHeight > LD_MAX_HEIGHT || newHeight < LD_MIN_HEIGHT) 
          error = true;
        break;
      case 'TL':
        if (newHeight > TL_MAX_HEIGHT || newHeight < TL_MIN_HEIGHT) 
          error = true;
        break;
      default:
        break;
    }
    return error;
  }

  const handleOpeningHeightRequest = (newHeight, side, productId) => {
    const error = calculateHeightError(newHeight, productId);
    if (newHeight === side.height || error) return;
    const queryData = [];
    if (productId === 'LD' && projectData.userType === DISTRIBUTOR_USER) {
      queryData.push({ height: newHeight, id: side.id });
    } else {
      projectData.sides.forEach((side) => {
        queryData.push({ height: newHeight, id: side.id });
      });
    }
    makeUpdateRequest(SIDE_UPDATE_PATH, queryData);
    const sides = [...projectData.sides];
    sides.forEach((s, index) => {
      if (s.id === side.id) {
        sides[index].height = newHeight;
      }
    });
    projectData.openingHeight = newHeight;
    dispatchProjectData({ type: 'opening_height', payload: newHeight });
    dispatchProjectData({ type: 'sides', payload: sides });

    validateHeightAndSide();
  };

  const handleQuantityRequest = (newQuantity) => {
    const data = { count: newQuantity };
    updateDrawing(projectData.drawingId, data, projectData.userData)
      .then((data) => {
        const { count } = data;
        dispatchProjectData({
          type: 'quantity',
          payload: count,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatchProjectData({type: 'remove_user_data'});
          window.location.reload();
        }
        console.error('error change quantity', error);
      });
  };

  const handleProfileColorRequest = (newColor) => {
    const colorValue = newColor.value;
    console.log('handle profile color', colorValue);
    if (colorValue === projectData.profileColor) return;

    const parameter = { parameters: { profileColor: colorValue } };
    const queryData = [];
    projectData.sides.forEach((side) => {
      queryData.push({
        type: projectData.currentProduct.cieloId,
        id: side.productId,
        ...parameter,
      });
    });

    makeUpdateRequest(PRODUCT_UPDATE_PATH, queryData);
    dispatchProjectData({ type: 'profile_color', payload: colorValue });
  };

  const handleGlassRequest = (newGlass, action) => {
    const glassValue = parseInt(newGlass.value);
    const type = action.name;

    if (glassValue === projectData.glass && action.centinel === undefined) return;

    let parameter;
    const glassType = type;

    if (projectData.currentProduct.id === 'LD') {
      if (type.includes('upper')) parameter = { upper_glass: glassValue };
      else parameter = { lower_glass: glassValue };
    } else parameter = { glass: glassValue };

    const queryData = [];
    projectData.sides.forEach((side) => {
      queryData.push({
        type: projectData.currentProduct.cieloId,
        id: side.productId,
        parameters: { ...parameter },
      });
    });

    makeUpdateRequest(PRODUCT_UPDATE_PATH, queryData);
    dispatchProjectData({ type: glassType, payload: glassValue });
    console.log(projectData);
  };

  const calculateSideError = (newLength, productId) => {
    let error = false;
    switch (productId){
      case 'PL':
        if (newLength > PL_MAX_WIDTH || newLength < PL_MIN_WIDTH) 
          error = true;
        break;
      case 'LD':
        if (newLength > LD_MAX_WIDTH || newLength < LD_MIN_WIDTH) 
          error = true;
        break;
      case 'TL':
        if (newLength > TL_MAX_WIDTH || newLength < TL_MIN_WIDTH) 
          error = true;
        break;
      default:
        break;
    }
    return error;
  }

  const handleSideLengthRequest = (newLength, side, productId) => {
    const error = calculateSideError(newLength, productId);

    if (newLength === side.length || error) return;
    
    const queryData = [{ width: newLength, id: side.id }];
    makeUpdateRequest(SIDE_UPDATE_PATH, queryData);
    
    const sides = [...projectData.sides];
    sides.forEach((s, index) => {
      if (s.id === side.id) {
        sides[index].length = newLength;
      }
    });

    dispatchProjectData({ type: 'sides', payload: sides });
    dispatchProjectData({ type: 'current_length', payload: newLength });

    validateHeightAndSide();
  };

  const handleSideAngleRequest = (newAngle, index) => {
    if (newAngle === projectData.sides[index].angle) return;
    console.log('handle side length', newAngle);

    const queryData = [
      {
        angleRight: newAngle,
        id: projectData.sides[index].id,
      },
      {
        angleLeft: newAngle,
        id: projectData.sides[index + 1].id,
      },
    ];
    makeUpdateRequest(SIDE_UPDATE_PATH, queryData);

    const sides = [...projectData.sides];
    sides[index].angle = newAngle;
    dispatchProjectData({ type: 'sides', payload: sides });
  };

  const handleDistributorParameterChange = (name, value) => {
    const isChecked = value === 'yes';
    console.log('name', name);
    console.log('value', value);
    dispatchProjectData({ type: name, payload: isChecked });
  };

  const handleAdditionalNotesChange = (text) => {
    dispatchProjectData({ type: 'additional_notes', payload: text });
  };

  const validateHeightAndSide = () => {
    const productId = projectData.currentProduct.id;
    let tempError = false;

    projectData.sides.forEach((side) => {
      if(calculateHeightError(side.height, productId)) {
        tempError = true;
      } 
      if(calculateSideError(side.length, productId)) {
        tempError = true;
      }      
    });
    dispatchProjectData({ type: 'validation_error', payload: tempError });
  };

  return (
    <DataManagerContext.Provider
      value={{
        handleOpeningHeightRequest,
        handleDividerHeightRequest,
        handleDividerCheckRequest,
        handleQuantityRequest,
        handleProfileColorRequest,
        handleGlassRequest,
        handleSideLengthRequest,
        handleSideAngleRequest,
        handleDistributorParameterChange,
        handleAdditionalNotesChange,
        handleUpdate3DModel
      }}
    >
      {children}
    </DataManagerContext.Provider>
  );
};

export default DataManagerProvider;
