import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import useProjectData from '../../../providers/ProjectDataProvider/use';

export default function CheckInput({checktype}) {
  const { t } = useTranslation('common');
  const {
    projectData,
    dispatchProjectData,
  } = useProjectData();
  const [check, setCheck] = useState(projectData.deliverToDistributor);
  const handleChange = () => {
    dispatchProjectData({type: checktype, payload: !check})
    setCheck(!check);
  }
  const nameCheckBox = checktype === 'deliver_to_distributor' ? 'delivery_choice' : '';
  const defaultValue = checktype === 'deliver_to_distributor' ? ( check ? '0' : '1') : '';
  return (
    <label className="check-control">
      <input
        checked={check}
        onChange={handleChange}
        type="checkbox"
        id="customer-check"
        name={nameCheckBox}
        value={defaultValue}
      />
      &nbsp;&nbsp;
      {t(`customer_info.${checktype}`)}
    </label>
  )
}
