/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSSR, useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';
import useDataManager from '../../providers/DataManagerProvider/use';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

const SwitchParameter = ({ name, camelCaseName }) => {
  const { projectData } = useProjectData();
  const { handleDistributorParameterChange } = useDataManager();

  const { t } = useTranslation('common');
  const isChecked = projectData[camelCaseName];
  return (
    <>
      {projectData.projectId === null && <LoadingPlaceholder />}
      {projectData.projectId !== null && (
        <div>
          <div className="param-title">
            {t(`distributor_parameters.${name}`)}
          </div>
          <input
            type="radio"
            id={`${name}_yes`}
            name={name}
            value="yes"
            checked={isChecked}
            onChange={(event) => handleDistributorParameterChange(name, event.target.value)}
          />
          <label htmlFor={`${name}_yes`}>{t('yes')}</label>
          <input
            type="radio"
            id={`${name}_no`}
            name={name}
            value="no"
            checked={!isChecked}
            onChange={(event) => handleDistributorParameterChange(name, event.target.value)}
          />
          <label htmlFor={`${name}_no`}>{t('no')}</label>
        </div>
      )}
    </>
  );
};

export default SwitchParameter;
