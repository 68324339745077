const Products = [
  {
    name: 'glazing_balcony',
    imageName: 'glazing_balcony.jpg',
    alt: 'Parvekelasitus',
    id: 'PL',
    cieloId: 'nikaLasitus/glazingBalcony',
    hasTemplateSelection: true,
    defaultTemplate: '01',
    templates: [
      { name: '01', id: process.env.REACT_APP_PL_01_ID, img: '01' },
      { name: '02', id: process.env.REACT_APP_PL_02_ID, img: '02' },
      { name: '03', id: process.env.REACT_APP_PL_03_ID, img: '03' },
      { name: '04', id: process.env.REACT_APP_PL_04_ID, img: '04' },
    ],
    description: 'product_description',
    description_auth: 'product_description_auth'
  },
  {
    name: 'terrace_glazing',
    imageName: 'terrace_glazing.jpg',
    alt: 'Terassilasitus puitteeton',
    id: 'TL',
    cieloId: 'nikaLasitus/terraceGlazing',
    hasTemplateSelection: true,
    defaultTemplate: '01',
    templates: [
      { name: '01', id: process.env.REACT_APP_TL_01_ID, img: '01' },
      { name: '02', id: process.env.REACT_APP_TL_02_ID, img: '02' },
      { name: '03', id: process.env.REACT_APP_TL_03_ID, img: '03' },
      { name: '04', id: process.env.REACT_APP_TL_04_ID, img: '04' },
    ],
    description: 'product_description',
    description_auth: 'product_description_auth'
  },
  {
    name: 'sliding_door',
    imageName: 'sliding_door.jpg',
    alt: 'Liukulasitus puitteellinen',
    id: 'LD',
    cieloId: 'nikaLasitus/railing_door',
    hasTemplateSelection: true,
    defaultTemplate: '01',
    templates: [
      { name: '01', id: process.env.REACT_APP_LD_01_ID, img: '01' },
      { name: '02', id: process.env.REACT_APP_LD_02_ID, img: '05' },
      { name: '03', id: process.env.REACT_APP_LD_03_ID, img: '06' },
      { name: '04', id: process.env.REACT_APP_LD_04_ID, img: '07' },
    ],
    description: 'product_description',
    description_auth: 'product_description_auth'
  },
];

export default Products;
