import React from 'react';
import { useTranslation } from 'react-i18next';
import ProjectService from '../../services/ProjectService';
import useProjectData from '../../providers/ProjectDataProvider/use';
import TextInput from '../Configurator/parameters/TextInput';

import './customer_info.css';
import { SECTION_SUMMARY } from '../../constants/sections';
import { EXTERNAL_USER } from '../../constants/userTypes';
import CheckInput from '../Configurator/parameters/CheckInput';

export default function CustomerInfo() {
  const { t } = useTranslation('common');
  
  const {
    setCustomerInfo,
    projectData,
    dispatchProjectData,
  } = useProjectData();
  const fields = ProjectService.getCustomerParameters(projectData.userType);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = Array.from(document.querySelectorAll("#customer-form input"));
    const data = {}
    for (let item = 0; item < form.length; item += 1){
      data[form[item].name] = form[item].value;
      dispatchProjectData({ type: form[item].name, payload: form[item].value})
    }
    ProjectService.requestCustomerChanges(projectData, data);
    dispatchProjectData({ type: 'current_section', payload: SECTION_SUMMARY });
  };

  const external = projectData.userType === EXTERNAL_USER;
  return (
    <div id="customer-info-container">
      <h2>Yhteystiedot</h2>
      <p>
        {
          (external &&
            t('product_selection.product_description')
          )
        }
        {
          (!external &&
            t('product_selection.product_description_auth')
          )
        }
      </p>      
      <form
        className="form-container row"
        id="customer-form"
        onSubmit={handleSubmit}
      >
        <div className="col-sm-6 col-12">
          <h4>Anna käyttäjätiedot</h4>
        </div>
        <div className="col-sm-6 col-12">
          {!external && <CheckInput checktype='deliver_to_distributor' />}
        </div>
        <div className="clean" />
        {fields.map((field) => 
        {
          const isRequired = external ? true : field.isRequired;
        return (
          <TextInput
            name={field.name}
            label={t(`customer_info.${field.name}`)}
            className={field.className}
            type={field.type}
            handleOnBlur={(value) => { setCustomerInfo(field.name, value) }}
            key={field.name}
            required={isRequired}
            defaultValue={projectData[field.camelCase] ?? ''}
          />
        )})}
      </form>
    </div>
  );
}