const reducer = (state, action) => {
  switch (action.type) {
    case 'project_name':
      return { ...state, projectName: action.payload };
    case 'user_ip':
      return { ...state, userIp: action.payload };
    case 'share_id':
      return { ...state, shareId: action.payload };
    case 'project_id':
      return { ...state, projectId: action.payload };
    case 'drawing_id':
      return { ...state, drawingId: action.payload };
    case 'drawing_data':
      return { ...state, drawingData: action.payload};
    case 'validation_error':
      return { ...state, validationError: action.payload};
    case 'additional_notes':
      return { ...state, additionalNotes: action.payload };
    case 'opening_height':
      return { ...state, openingHeight: action.payload };
    case 'divider_check':
      return { ...state, dividerCheck: action.payload };
    case 'divider_height':
      return { ...state, dividerHeight: action.payload };
    case 'profile_color':
      return { ...state, profileColor: action.payload };
    case 'sides':
      return { ...state, sides: action.payload };
    case 'current_length':
      return { ...state, currentLength: action.payload };
    case 'delivered_parts':
      return { ...state, deliveredInParts: action.payload };
    case 'quantity':
      return { ...state, quantity: action.payload };
    case 'glass':
      return { ...state, glass: action.payload };
    case 'lower_glass':
      return { ...state, lowerGlass: action.payload };
    case 'upper_glass':
      return { ...state, upperGlass: action.payload };
    case 'color_options':
      return { ...state, colorOptions: action.payload };
    case 'loading_cost':
      return { ...state, isLoadingCost: action.payload };
    case 'loading_model':
      return { ...state, isLoadingModel: action.payload };
    case 'project_cost':
      return { ...state, projectCost: action.payload };
    case 'current_section':
      return { ...state, currentSection: action.payload };
    case 'current_side':
      return { ...state, currentSide: action.payload };
    case 'current_template':
      return { ...state, currentTemplate: action.payload };
    case 'current_product':
      return { ...state, currentProduct: action.payload };
    case 'profile_options':
      return { ...state, profileOptions: action.payload };
    case 'full_glasses':
      return { ...state, fullGlasses: action.payload };
    case 'glass_options':
      return { ...state, glassOptions: action.payload };
    case 'pending_requests':
      return { ...state, pendingRequests: action.payload };
    case 'glass_mouldings':
      return { ...state, glassMouldings: action.payload };
    case 's1_lists':
      return { ...state, s1Lists: action.payload };
    case 'perforation_upper_profile':
      return { ...state, perforationUpperProfile: action.payload };
    case 'perforation_lower_profile':
      return { ...state, perforationLowerProfile: action.payload };
    case 'draining_holes':
      return { ...state, drainingHoles: action.payload };
    case 'top_seal':
      return { ...state, topSeal: action.payload };
    case 'customer_address':
      return { ...state, customerAddress: action.payload };
    case 'customer_postal_code':
      return { ...state, customerPostalCode: action.payload };
    case 'customer_city':
      return { ...state, customerCity: action.payload };
    case 'customer_phone':
      return { ...state, customerPhone: action.payload };
    case 'customer_email':
      return { ...state, customerEmail: action.payload };
    case 'customer_country':
      return { ...state, customerCountry: action.payload };
    case 'deliver_to_distributor':
        return { ...state, deliverToDistributor: action.payload };
    case 'delivery_choice':
      return { ...state, deliveryChoice: action.payload };
    case 'first_name':
      return { ...state, firstName: action.payload };
    case 'last_name':
      return { ...state, lastName: action.payload };
    case 'attached_file':
      return { ...state, attachedFile: action.payload };
    case 'user_data':
      localStorage.setItem('userData', JSON.stringify(action.payload));
      return { ...state, userData: action.payload, };
    case 'remove_user_data':
      localStorage.removeItem('userData');
      return {...state, userData: {}}
    case 'user_type':
      return { ...state, userType: action.payload, };
    case 'clear_project':
      return {
        ...state,
        projectId: null,
        isLoadingModel: true,
        isLoadingCost: true,
        pendingRequests: null,
        sides: null,
        quantity: null,
        openingHeight: null,
        profileColor: null,
        glass: null,
        shareId: null,
        additionalNotes: null,
        glassMouldings: null,
        s1Lists: null,
        perforationUpperProfile: null,
        perforationLowerProfile: null,
        drainingHoles: null,
        topSeal: null,
        validationError: false,
        drawingData: null,
        attachedFile: ''
      };
    default:
      throw new Error(`Not a valid action: ${action.type}`);
  }
};

export default reducer;
