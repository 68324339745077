import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCost } from '../../api';
import { EXTERNAL_USER } from '../../constants/userTypes';
import useProjectData from '../../providers/ProjectDataProvider/use';
import './cost.css';

const Cost = ({ orientationCss = 'text-right' }) => {
  const { t } = useTranslation('common');
  const { projectData, dispatchProjectData } = useProjectData();
  const vatInfo =
    projectData.userType === 'distributor' ? ' (alv 0%)' : ' (sis. alv)';

  const extCost = useMemo(() => {
    const value = projectData.userType === EXTERNAL_USER ? 300 : 0;
    const cost = projectData.projectCost + value;
    const realAmount =
      projectData.userType === EXTERNAL_USER ? Math.ceil(cost / 100) * 100 : cost;
    return realAmount;
  }, [projectData.projectCost, projectData.userType]);
  useEffect(() => {
    getCost(projectData.projectId, projectData.userData)
      .then((data) => {
        const { price, priceWithVat } = data;
        const currentPrice =
          projectData.userType === 'distributor' ? price : priceWithVat;
          dispatchProjectData({
            type: 'project_cost',
            payload: parseFloat(currentPrice),
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatchProjectData({type: 'remove_user_data'});
          window.location.reload();
        }
      });
  }, [projectData.projectId, projectData.userData])
  
  return (
    <div className={orientationCss}>
      <h4 className="cost-text">{`${t('price') + vatInfo}`}</h4>
      <hr className="line-container" />
      <p className="cost-number text-right">{`${extCost} EUR`}</p>
    </div>
  );
};

export default Cost;
