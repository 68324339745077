/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../components/CustomAlert';
import useProjectData from '../providers/ProjectDataProvider/use';
import { sendEmail, setProjectAsOrdered } from '../api';

import {
  SECTION_TEMPLATE_SELECTION,
  SECTION_PARAMETERS,
  SECTION_PRODUCT_SELECTION,
  SECTION_CUSTOMER_INFO,
  SECTION_SUMMARY,
  SECTION_ADDITIONAL_NOTES,
} from '../constants/sections';
import ProjectService from '../services/ProjectService';
import { DISTRIBUTOR_USER, EXTERNAL_USER } from '../constants/userTypes';
import { NIKA_EMAIL, MAIL_TEMPLATE_ID, MAIL_TEMPLATE_ID_AUTH } from '../constants/emailAddress';

const ModelSelection = () => {
  const { t } = useTranslation('common');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { projectData, dispatchProjectData } = useProjectData();
  
  const showNextButton = useMemo(() => {
    if (projectData.currentSection === SECTION_PRODUCT_SELECTION) return false;
    return true;
  });
  const backButtonAvailable = true;
  const showBackButton =
    projectData.currentSection !== SECTION_PRODUCT_SELECTION;

  const isCustomerInfoSection =
    projectData.currentSection === SECTION_CUSTOMER_INFO;

  const [disabledButton, setDisabledButton] = useState(projectData.currentSection === SECTION_TEMPLATE_SELECTION ? true : projectData.validationError);

  const [ isOpen, setIsOpen ] = useState(false);

  const handleModalState = () => (isOpen)

  const handleClosingModal = () => {
    setIsOpen(false);
  }

  const handleIsAccepted = () => {
    if(isOpen) {
      let emailAdresses = [];
      const summaryComplete = document.getElementById('summary').outerHTML.replaceAll('<label class="no-content-title ', '<p style="width:33%; float: left; font-weight: bold; color: #0080e2" class="').replaceAll("<label", '<p style="width:33%; float: left; font-weight: bold"').replaceAll("label><span",'p><p style="width:66%; float: left"').replaceAll("span>",'p>').replaceAll('class="clean"', 'style="clear:both;"').replaceAll('id="attached-image"', 'style="max-height: 150px; max-width: 250px;"');
      const customerData = ProjectService.getCustomerData(projectData);
      const breakChar = projectData.attachedFile.indexOf(',');
      const attachmentImage = [];
      if( breakChar > 0) {
        const replaceString = projectData.attachedFile.substring(0, breakChar+1);
        const imageType = replaceString.replace('data:', '').replace(';base64,','');
        attachmentImage.push({
          content: projectData.attachedFile.replace(replaceString, ''),
          filename: `attachment.${imageType.split('/').pop()}`,
          type: imageType,
          disposition: "attachment"
        });
      }
      const data = {
        projectId: projectData.projectId,
        projectData: {
          cost: projectData.userType === EXTERNAL_USER ? Math.ceil(projectData.projectCost / 100) * 100 : projectData.projectCost,
          productInformation: summaryComplete,
          isExternalUser: projectData.userType === EXTERNAL_USER,
          comments: projectData.drawingData.parameters.UserComments,
          projectLink: `${process.env.REACT_APP_IFRAME_URL}3d.php?s=${projectData.shareId}&loc=3.75%2C6.58%2C-6.53&look=0.00%2C0.00%2C0.00&lang=fi`,
          mailTemplateId: projectData.userType === DISTRIBUTOR_USER ? MAIL_TEMPLATE_ID_AUTH : MAIL_TEMPLATE_ID,
          attachment: attachmentImage
        },
        customerData
      };
      if (projectData.userType === DISTRIBUTOR_USER) {
        emailAdresses.push(projectData.userData.email);
      } else {
        setProjectAsOrdered(data.projectId)
        .then((response) => {
          console.log('project ordered response =>',response);
        })
        .catch((error) => {
          console.error(error);
        });
        emailAdresses = emailAdresses.concat(NIKA_EMAIL);
        emailAdresses.push(projectData.customerEmail);
      }
      data.email = emailAdresses;
      sendEmail(data)
      .then((response) => {
        console.log('sendEmail response =>',response);
        alert('Email sent!');
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert('Email could not be sent!');
      }); 
    }
    setIsOpen(false);
  }

  const handleNext = () => {
    if(projectData.validationError || projectData.projectId === null) {
      return;
    }
    
    let newSection;

    if (projectData.currentSection === SECTION_PRODUCT_SELECTION) {
      newSection = projectData.currentProduct.hasTemplateSelection
        ? SECTION_TEMPLATE_SELECTION
        : SECTION_PARAMETERS;
    } else {
      newSection = projectData.currentSection + 1;
    }

    if (projectData.currentSection === SECTION_TEMPLATE_SELECTION)
      document.getElementById('btn-menu').checked = true;

    if (projectData.userType !== "external" && newSection === SECTION_ADDITIONAL_NOTES) {
      newSection = SECTION_CUSTOMER_INFO;
    }

    if (projectData.currentSection === SECTION_SUMMARY) {
      setIsOpen(true);
      newSection = SECTION_SUMMARY;
    }

    if (newSection === SECTION_CUSTOMER_INFO) {
      setDisabledButton(true);
      if(projectData.userType === DISTRIBUTOR_USER) {
        ProjectService.requestDrawingInfo(projectData)
        .then((data) => {
          dispatchProjectData({type: 'drawing_data', payload: data });
          if(document.getElementById("notes-area")){
            document.getElementById("notes-area").innerHTML = data.parameters.UserComments;
          }
          setDisabledButton(false);
        })
      } else {
        ProjectService.requestAditionalNotesChanges(projectData)
        .then(() => {
          ProjectService.requestDrawingInfo(projectData)
          .then((data) => {
            dispatchProjectData({type: 'drawing_data', payload: data });
            if(document.getElementById("notes-area")){
              document.getElementById("notes-area").innerHTML = data.parameters.UserComments;
            }
            setDisabledButton(false);
          })
        });
      }
      projectData.sides.forEach((side, index) => {
        ProjectService.requestSideInfo(side.id, projectData)
        .then((response) => {
          const data = response.pop();
          try {
            projectData.sides[index].title = `Sivu ${index+1}`;
            if(projectData.currentProduct.id === 'LD') {
              projectData.sides[index].hole_split = data.parameters.hole_split;
              projectData.sides[index].leafs_left = data.parameters.leafs_left;
              projectData.sides[index].leafs_right = data.parameters.leafs_right;
              projectData.sides[index].length_leafs = parseInt(side.length / (data.parameters.leafs_left+data.parameters.leafs_right));
              projectData.fullGlasses.forEach((glass) => {
                if(parseInt(glass.value) === data.parameters.upper_glass) {
                  projectData.sides[index].upper_glass = glass.label;
                }
                if(parseInt(glass.value) === data.parameters.lower_glass) {
                  projectData.sides[index].lower_glass = glass.label;
                }
              });                       
            } else {
              projectData.sides[index].hole_split = false; // ask for this parameter
              projectData.sides[index].leafs_left = data.parameters.panelsLeft;
              projectData.sides[index].leafs_right = data.parameters.panelsRight;
              projectData.sides[index].length_leafs = parseInt(side.length / (data.parameters.panelsLeft+data.parameters.panelsRight));
              projectData.fullGlasses.forEach((glass) => {
                if(parseInt(glass.value) === data.parameters.glass) {
                  projectData.sides[index].upper_glass = glass.label;
                }
              });
            }
            ProjectService.requestProductInfoValues(side.productId, projectData)
                .then((response_product) => {
                  projectData.sides[index].parameterValues = response_product;
                  if(projectData.sides.length-1 === index){
                    if(disabledButton) {
                      setDisabledButton(false);
                    }
                  }
                });
          } catch (error) {
            console.log("Not data");
          }     
        })
      });
    }

    dispatchProjectData({ type: 'current_section', payload: newSection });
  };

  const handleBack = () => {
    if (!backButtonAvailable) return;

    let newSection;

    if (projectData.currentSection === SECTION_PARAMETERS) {
      newSection = projectData.currentProduct.hasTemplateSelection
        ? SECTION_TEMPLATE_SELECTION
        : SECTION_PRODUCT_SELECTION;
    } else {
      newSection = projectData.currentSection - 1;
    }

    if (
      newSection === SECTION_TEMPLATE_SELECTION &&
      projectData.currentSection === SECTION_PARAMETERS
    )
      document.getElementById('btn-menu').checked = true;

    if (newSection === SECTION_PRODUCT_SELECTION) {
      projectData.currentProduct = null;
    }

    if (projectData.userType !== "external" && newSection === SECTION_ADDITIONAL_NOTES) {
      newSection = SECTION_PARAMETERS;
    }

    dispatchProjectData({ type: 'current_section', payload: newSection });
  };

  const nextButtonText =
    projectData.currentSection === SECTION_PRODUCT_SELECTION
      ? t('continue')
      : projectData.currentSection === SECTION_SUMMARY ? t('send') : t('next');

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(projectData.colorOptions !== null && projectData.fullGlasses !== null){
      setDisabledButton(false);
    }
  }, [projectData.colorOptions, projectData.fullGlasses]);

  const showAsFloatingButton =
    windowWidth < 860 &&
    projectData.currentSection !== SECTION_PRODUCT_SELECTION && 1 === 2;

  return (
    <div
      className={`nav-buttons-container ${
        showAsFloatingButton ? 'floating-container' : ''
      }`}
    >
      {showBackButton && (
        <button
          onClick={handleBack}
          className={`nav-button back-button ${
            backButtonAvailable ? 'available-button' : 'unavailable-button'
          }`}
          type="button"
        >
          {t('back')}
        </button>
      )}
      {showNextButton && !isCustomerInfoSection && (
        <button
          onClick={handleNext}
          className={disabledButton ? "nav-button next-button available-button disabled-button" : "nav-button next-button available-button"} 
          type="button"
          disabled={disabledButton}
        >
          {nextButtonText}
        </button>
      )}
      {showNextButton && isCustomerInfoSection && (
        <button
          className={disabledButton ? "nav-button next-button available-button disabled-button" : "nav-button next-button available-button"} 
          form="customer-form"
          type="submit"
          disabled={disabledButton}
        >
          {nextButtonText}
        </button>
      )}
      <CustomAlert type='confirm' message='Oletko varma?' closeModal={handleClosingModal} modalIsOpen={handleModalState} isAccepted={handleIsAccepted} />     
    </div>
  );
};

export default ModelSelection;
