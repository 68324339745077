/* eslint-disable prefer-destructuring */

import { EXTERNAL_USER } from '../constants/userTypes';

/* eslint-disable class-methods-use-this */
class ParametersService {
  getGeneralParameters() {
    const parameters = [];

    parameters.push({ name: 'opening_height' });
    parameters.push({ name: 'quantity' });
    parameters.push({ name: 'profile_color' });
    parameters.push({ name: 'glass' });
    
    return parameters;
  }

  getSideParameters(product, template) {
    const parameters = [];
  
    if (product.id === 'LD') {

      if (template.name === '01' || template.name === '04')
        parameters.push({ name: 'void' });

      parameters.push({ name: 'clear' });

      parameters.push({ name: 'divider_check' });
      parameters.push({ name: 'divider_height' });
      parameters.push({ name: 'upper_glass' });
      parameters.push({ name: 'lower_glass' });
    }

    return parameters;
  }

  getDistributorParameters(userType, product) {
    if (userType === EXTERNAL_USER) {
      return [];
    }
    const parameters = [];
    switch (product) {
      case 'terrace_glazing':
        parameters.push({
          name: 'glass_mouldings',
          camelCaseName: 'glassMouldings',
        });
        parameters.push({
          name: 'perforation_upper_profile',
          camelCaseName: 'perforationUpperProfile',
        });
        return parameters;
      case 'glazing_balcony':
        parameters.push({
          name: 'glass_mouldings',
          camelCaseName: 'glassMouldings',
        });
        parameters.push({
          name: 'perforation_upper_profile',
          camelCaseName: 'perforationUpperProfile',
        });
        parameters.push({ name: 's1_lists', camelCaseName: 's1Lists' });
        parameters.push({
          name: 'perforation_lower_profile',
          camelCaseName: 'perforationLowerProfile',
        });
        parameters.push({
          name: 'draining_holes',
          camelCaseName: 'drainingHoles',
        });
        parameters.push({ name: 'top_seal', camelCaseName: 'topSeal' });
        return parameters;
      default:
        return parameters;
    }
  }
}

export default new ParametersService();
