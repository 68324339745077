export const PL = 'nikaLasitus/glazingBalcony';
export const TL = 'nikaLasitus/terraceGlazing';
export const LD = 'nikaLasitus/railing_door';

export const resolveProductType = (productType) => {
  switch (productType) {
    case 'PL':
      return PL;
    case 'TL':
      return TL;
    case 'LD':
      return LD;
    default:
      throw new Error ("product type doesn't exist");
  }
}

export const resolveProductTypeName = (productType) => {
  switch (productType) {
    case 'glazing_balcony':
      return "PL";
    case 'terrace_glazing':
      return "TL";
    case 'sliding_door':
      return "LD";
    default:
      throw new Error ("product type doesn't exist");
  }
}