/* eslint-disable class-methods-use-this */
const prefix = 'api/v1/';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json();
}

class TokenRestService {

  // eslint-disable-next-line class-methods-use-this
  buildUrl(url) {
    return process.env.REACT_APP_IFRAME_URL + prefix + url;

  }

  requestLogin(credentials) {
    const url = this.buildUrl('login_token/get_token');
    const options = {
      method: 'POST',
      body: JSON.stringify(credentials),
    };
    return this.sendRequest(url, options);
  }

  // eslint-disable-next-line class-methods-use-this
  getOptions(user_id, token, type) {
    return {
      method: type,
      headers: new Headers({
        'Authorization': `Bearer ${user_id}:${token}`,
        'Content-Type': 'application/json'
      }),
    };
  }

  request(type, path, data, userData = {}) {
    const { user_id, token } = userData;
    const url = this.buildUrl(path);
    const options = this.getOptions(user_id, token, type);
    if (data) {
      options.body = JSON.stringify(data);
    }
    return this.sendRequest(url, options);
  }

  sendRequest(url, options) {
    return fetch(url, options)
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => data)
      .catch((error) => {
        if (error.response === undefined) {
          error.response = {
            status: 500,
            message: 'Failed to connect server',
          };
        }

        throw error.response;
      });
  }
}

export default new TokenRestService();
