import './loading_placeholder.css';
import React from 'react';

const LoadingPlaceholder = () => (
  <div className="placeholder">
    <div className="animated-background" />
  </div>
);

export default LoadingPlaceholder;
