/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable vars-on-top */
/* eslint-disable no-else-return */
/* eslint-disable no-var */
/* eslint-disable no-restricted-globals */
import React from 'react';

/**
 * Service for Rest requests
 *
 * @class RestService
 * @extends {React.Component}
 */
class RestService extends React.Component {
  /**
   * Check response status as Fetch
   *
   * @param {any} response
   * @returns
   */
  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  /**
   *
   *
   * @param {Object} response
   * @returns Object
   */
  parseJSON(response) {
    return response.json();
  }

  sendRequest(url, options) {
    return fetch(url, options)
      .then(this.checkStatus)
      .then(this.parseJSON)
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = {
            status: 500,
            message: 'Failed to connect server',
          };
        }

        throw error.response;
      });
  }

  buildUrl(url) {
    const queryString = '';

    return process.env.REACT_APP_BACK_URL + url + queryString;
  }

  /**
   *
   *
   * @param {Object} data
   * @returns Object
   */
  resolveParameters(data) {
    let query = '';
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        query += `${this.resolveParametersFromArray(key, value)}`;
      } else {
        query += `&${key}=${value}`;
      }
    }
    return query;
  }

  resolveParametersFromArray(key, value) {
    let query = '';
    value.map((value) => (query += `&${key}%5B%5D=${value}`));
    return query;
  }

  getOptions(nocache, type) {
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json; charset=UTF-8');

    if (nocache === true) {
      myHeaders.append('Cache-Control', 'no-cache, no-store');
    }
    return {
      method: type,
      mode: 'cors',
      headers: myHeaders,
    };
  }

  /**
   * Make reqular json request
   *
   * @param {String} type
   * @param {String} url 
   * @param {Object} data
   * @returns Object
   */
  // eslint-disable-next-line no-unused-vars
  request(type, url, data, userData = {}, nocache = true) {
    url = this.buildUrl(url);
    const options = this.getOptions(nocache, type);

    if (data !== null) {
      if (type === 'POST' || type === 'PUT') {
        data = JSON.stringify(data);
        options.body = data;
      }
    }

    return this.sendRequest(url, options);
  }

  /**
   * Make get json request with parameters
   *
   * @param {String} type
   * @param {String} url
   * @param {Object} data
   * @returns Object
   */
  requestGetWithParameters(url, data, nocache = true) {
    url = this.buildUrl(url);
    const options = this.getOptions(nocache, 'GET');
    if (data !== null) {
      url += this.resolveParameters(data);
    }

    return this.sendRequest(url, options);
  }
}

export default new RestService();
