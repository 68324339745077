/* eslint-disable arrow-body-style */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';
import TemplateSelection from './TemplateSelection';
import Cost from '../../components/Cost';
import Parameters from './Parameters';
import {
  SECTION_TEMPLATE_SELECTION,
  SECTION_PARAMETERS,
} from '../../constants/sections';
import { EXTERNAL_USER } from '../../constants/userTypes';
import CieloModel from '../CieloModel';
import './configurator.css';

const Configurator = () => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');
  return (
    <>
      <input type="checkbox" id="btn-menu" />
      <div id="main-content">
        <label className="btn-logo" htmlFor="btn-menu">
          <div
            id="show-hide-button"
            data-edit-text={t('edit_configurator')}
            data-see3d-text={t('back_configurator')}
          >
            <div id="arrow" />
          </div>
        </label>
        <div id="parameters">
          {projectData.currentSection === SECTION_TEMPLATE_SELECTION && (
            <TemplateSelection />
          )}
          {projectData.currentSection === SECTION_PARAMETERS && <Parameters />}
        </div>
        <div id="model-container">
          <CieloModel />
        </div>
        <div className="clear-both" />
      </div>
      <div className="cost-space">
        <Cost orientationCss="text-left cost-container" />
        {projectData.userType === EXTERNAL_USER && (
          <p className="cost-notes">{t('cost_notes')}</p>
        )}
      </div>
    </>
  );
};

export default Configurator;
