import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import CustomAlert from '../CustomAlert';
import useProjectData from '../../providers/ProjectDataProvider/use';
import { MAX_FILE_SIZE } from '../../constants/validations';
import './image_upload.css';

export default function ImageUpload(altText) {
  const { t } = useTranslation('common');
  const { projectData, dispatchProjectData } = useProjectData();
  const check = projectData.attachedFile.length > 0;
  const [messageM, setMessageM] = useState('');
  const [typeModal, setTypeModal] = useState('message');
  const imageUpload = (e) => {
    const file = e.target.files[0];
    const maxAllowedSize = MAX_FILE_SIZE * 1024 * 1024;
    if (e.target.files[0].size > maxAllowedSize) {
      setIsOpen(true);
      setTypeModal('message');
      setMessageM(t('image_size_restriction'));
    } else {
      getBase64(file).then(base64 => {
        setTypeModal('image-preview');
        setMessageM(base64);
        dispatchProjectData({type: 'attached_file', payload: base64});
      });
    }
  };
  const getBase64 = (file) => {
    const promise = new Promise((resolve,reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
     });
    return promise;
  };
  const removeImage = () => {
    if(check) {
      dispatchProjectData({type: 'attached_file', payload: ''});
    }
  }
  const [ isOpen, setIsOpen ] = useState(false);

  const handleModalState = () => (isOpen)

  const handleClosingModal = () => {
    setIsOpen(false);
  }
  
  return (
    <>
      {!check && (
        <div className='image-upload col-12'>
          <label htmlFor="imageFile" className="btn">{t('select-image')}</label>
          <input
            type="file" 
            id="imageFile" 
            name='imageFile' 
            onChange={imageUpload} 
            accept=".jpeg, .png, .jpg"
          />
        </div>
      )}
      {check && (
        <div className='image-preview'>
          <button type='button' onClick={() => setIsOpen(true)}>
            <img src={projectData.attachedFile} alt={altText} />
          </button>
          <div className='clear-both' />
          <button className='nav-button' type='button' onClick={removeImage}>{t('remove-image')}</button>
        </div>        
      )}
      <CustomAlert type={typeModal} message={messageM} closeModal={handleClosingModal} modalIsOpen={handleModalState} />
    </>    
  )
}
