/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */

import { setCustomerInfo, getDrawing, getDrawingInfo, getSideInfo, getProductInfo, getProductInfoValues } from '../api';
import { EXTERNAL_USER } from '../constants/userTypes';
import ParametersService from './ParametersService';
import RestService from './RestService';

const PRODUCT_UPDATE_PATH = 'productDefinitions/updateMultiple';

class ProjectService {

  getProductIdfromSide(productDefinitions, sideId) {
    return productDefinitions.find( product => product.side === sideId);
  }

  getSidesInfo(sides, productDefinitions) {
    const sidesInfo = [];
    sides.forEach((side) => {
      const product = this.getProductIdfromSide(productDefinitions, side.id);
      if (side.width > 100) {
        sidesInfo.push({
          id: side.id,
          index: side.index,
          length: side.width,
          angle: side.angleRight,
          productId: product.id,
          height: side.height
        });
      }
    });
    sidesInfo.sort((s1, s2) => s1.index - s2.index);

    return sidesInfo;
  }

  getProjectInfo(data) {
    const projectInfo = [];
    const projectId = data.project;
    const { shareId } = data.targetInfo;
    const { sides, productDefinitions } = data.targetInfo.drawings[0];
    const { id } = data.targetInfo.drawings[0].drawing;
    const openingHeight = sides.result[0].height;
    const dividerHeight =
      productDefinitions.result[0].parameters.hole_split_offset;
    const profileColor = productDefinitions.result[0].color;
    const { glass, lower_glass } = productDefinitions.result[0].parameters;
    const glassValue = glass ?? lower_glass;
    const deliveredInParts = productDefinitions.result[0].parameters.deliveredInParts ?? false;
    const sidesInfo = this.getSidesInfo(
      sides.result,
      productDefinitions.result
    );
    
    projectInfo.push({ type: 'share_id', payload: shareId });
    projectInfo.push({ type: 'project_id', payload: projectId });
    projectInfo.push({ type: 'drawing_id', payload: id });
    projectInfo.push({ type: 'opening_height', payload: openingHeight });
    projectInfo.push({ type: 'divider_height', payload: dividerHeight });
    projectInfo.push({ type: 'divider_check', payload: false });
    projectInfo.push({ type: 'quantity', payload: 1 });
    projectInfo.push({ type: 'profile_color', payload: profileColor });
    projectInfo.push({ type: 'glass', payload: glassValue });
    projectInfo.push({ type: 'sides', payload: sidesInfo });
    projectInfo.push({ type: 'delivered_parts', payload: deliveredInParts });
    
    return projectInfo;
  }

  getDefaultTemplateFromProduct(product) {
    if (product.hasTemplateSelection) {
      return product.templates.find(
        ({ name }) => name === product.defaultTemplate
      );
    }

    return product.template;
  }

  getProjectName(userIp, date, currentProduct, currentTemplate) {
    return `NIKA_${currentProduct.id}_${currentTemplate.name}_${userIp}_${date}`;
  }

  getCustomerParameters(userType) {
    const external = userType === EXTERNAL_USER
    const customerInfo = [];
    customerInfo.push({
      name: 'first_name',
      camelCase: 'firstName',
      className: 'col-md-6',
      type: 'text',
      isRequired: true,
    });
    customerInfo.push({
      name: 'last_name',
      camelCase: 'lastName',
      className: 'col-md-6',
      type: 'text',
      isRequired: true,
    });
    customerInfo.push({
      name: 'customer_phone',
      camelCase: 'customerPhone',
      className: 'col-md-6',
      type: 'tel',
      isRequired: false,
    });
    if (!external){
      customerInfo.push({
        name: 'project_name',
        camelCase: 'projectName',
        className: 'col-md-6',
        type: 'text',
        isRequired: true,
      });
    }
    customerInfo.push({
      name: 'customer_email',
      camelCase: 'customerEmail',
      className: 'col-md-12',
      type: 'email',
      isRequired: false,
    });
    customerInfo.push({
      name: 'customer_address',
      camelCase: 'customerAddress',
      className: 'col-md-12',
      type: 'text',
      isRequired: true,
    });
    customerInfo.push({
      name: 'customer_city',
      camelCase: 'customerCity',
      className: 'col-md-6',
      type: 'text',
      isRequired: false,
    });
    customerInfo.push({
      name: 'customer_postal_code',
      camelCase: 'customerPostalCode',
      className: 'col-md-6',
      type: 'text',
      isRequired: false,
    });
    customerInfo.push({
      name: 'customer_country',
      camelCase: 'customerCountry',
      className: 'col-md-6',
      type: 'text',
      isRequired: false,
    });

    return customerInfo;
  }

  getCustomerData(projectData) {
    const data = {
      name: projectData.projectName,
      customerName: `${projectData.firstName} ${projectData.lastName}`,
      customerAddress: projectData.customerAddress,
      customerCity: projectData.customerCity,
      customerCountry: projectData.customerCountry,
      customerPhone: projectData.customerPhone,
      customerPostalCode: projectData.customerPostalCode,
      customerEmail: projectData.customerEmail,
      deliverToDistributor: projectData.deliverToDistributor,
    };

    if (data.name === null) {
      delete data.name;
    }
    if (projectData.userType === EXTERNAL_USER) {
      delete data.deliverToDistributor;
    }
    return data;
  }

  getCustomerDataInfo(data) {
    const info = {
      name: data.project_name,
      customerName: `${data.first_name} ${data.last_name}`,
      customerAddress: data.customer_address,
      customerCity: data.customer_city,
      // customerCountry: projectData.customer_country,
      customerPhone: data.customer_phone,
      customerPostalCode: data.customer_postal_code,
      customerEmail: data.customer_email,
      deliveryChoice: data.delivery_choice
    };

    if (info.name == null ) {
      delete info.name;
    }
    return info;
  }

  getLabel(array, value, type) {
    const container = array.filter(a => parseInt(a.value) === parseInt(value))[0];
    let label = '';
    if (container) {
      label = container.label;
    }
    const defaultValue = type === 'glass' ? 'Kirkas karkaistu' : 'Säätökisko 4 raide';
    const result = label !== '' ? label : defaultValue;
    return result;
  }

  getParametersData(projectData) {
    const { quantity, sides } = projectData;
    const name = `${projectData.currentProduct.alt} ${quantity} kpl`;
    const sideOne = sides[0] ?? '';
    const sideTwo = sides[1] ?? '';
    const sideThree = sides[2] ?? '';

    const isExternal = projectData.userType === EXTERNAL_USER;
    const commonGroup = isExternal ? 'multisideExternal' : 'multiside';
    const sideGroup = isExternal ? 'onesideExternal' : 'oneside';

    const data = [
      { id: 1, name: 'product', content: name, hidden: false },
    ]
    let identifier = 2;
    if (sideOne.length > 0) {
      Object.keys(sideOne.parameterValues[commonGroup]).forEach((key) => {
        data.push({ id:identifier, name: key, content: sideOne.parameterValues[commonGroup][key], hidden: false });
        identifier += 1;
      });
      data.push({ id: identifier, name: 'side_one_separator', content: '', hidden: false });
      identifier += 1;
      Object.keys(sideOne.parameterValues[sideGroup]).forEach((key) => {
        data.push({ id:identifier, name: key, content: sideOne.parameterValues[sideGroup][key], hidden: false });
        identifier += 1;
      });
    }
    if (sideTwo.length > 0) {
      data.push({ id: identifier, name: 'side_two_separator', content: '', hidden: false });
      identifier += 1;
      Object.keys(sideTwo.parameterValues[sideGroup]).forEach((key) => {
        data.push({ id:identifier, name: key, content: sideTwo.parameterValues[sideGroup][key], hidden: false });
        identifier += 1;
      });
    }
    if (sideThree.length > 0) {
      data.push({ id: identifier, name: 'side_three_separator', content: '', hidden: false });
      identifier += 1;
      Object.keys(sideThree.parameterValues[sideGroup]).forEach((key) => {
        data.push({ id:identifier, name: key, content: sideThree.parameterValues[sideGroup][key], hidden: false });
        identifier += 1;
      });
    }
    
    return data.filter(e => !e.hidden );
  }

  getSpecialParametersData(projectData) {
    const data = {};
    const { sides } = projectData;
    const sideOne = sides[0] ?? '';

    if (projectData.userType === EXTERNAL_USER) {
      return {};
    }

    if (sideOne) {
      Object.keys(sideOne.parameterValues.extra).forEach((key) => {
        data[key] = sideOne.parameterValues.extra[key];
      });
    }

    return data;
  }

  requestCustomerChanges(projectData, data) {
    const customerData = this.getCustomerDataInfo(data);
    return setCustomerInfo(projectData.projectId, customerData, projectData.userData)
      .then((data) => {
        console.log('succesfull data', data.id);
        return true;
      })
      .catch((data) => {
        console.log(data);
        return false;
      });
  }

  resolveDistibutorParameters(projectData, product) {
    const parameters = {};
    const distributorParameters = ParametersService.getDistributorParameters(
      projectData.userType,
      product
    );
    distributorParameters.forEach((distParameters) => {
      parameters[distParameters.camelCaseName] =
        projectData[distParameters.camelCaseName] ?? false;
    });
    parameters.UserComments = projectData.additionalNotes;
    return parameters;
  }

  async requestAditionalNotesChanges(projectData) {
    const data = [];
    const productParameters = this.resolveDistibutorParameters(
      projectData,
      projectData.currentProduct.name
    );
    projectData.sides.forEach((side) =>
      data.push({
        id: side.productId,
        type: projectData.currentProduct.cieloId,
        parameters: productParameters,
      })
    );
    const dataNotes = await RestService.request('PUT', PRODUCT_UPDATE_PATH, data)
    .then((data1) => {
      const { result } = data1.pop();
      return result;
    })
    .catch((data2) => {
      console.log("Error saving aditional notes ", data2);
      return false;
    }); 
    return dataNotes;
  }

  /* return the color side */
  async requestSideInfo(sideId, projectData) {
    try {
      const dataDrawing = await getSideInfo(sideId, projectData.userData)
      .then((data) => {
          const { result } = data
          return result;
        });
      return dataDrawing;
    } catch (dataDrawing_1) {
      return 'None';
    }
  }

  /* return the latest drawing info */
  async requestDrawingInfo(projectData) {
    try {
      const data = await getDrawing(projectData.projectId, projectData.userData);
      const drawing = data.result.pop();
      try {
        const dataDrawing = await getDrawingInfo(drawing.id, projectData.userData);
        const drawingInfo = dataDrawing.result.pop();
        return drawingInfo;
      } catch (dataDrawing_1) {
        console.log("Error getDrawingInfo: ", dataDrawing_1);
        return false;
      }
    } catch (data_1) {
      console.log("Error getDrawing: ", data_1);
      return false;
    }
  }

  /* return the product info */
  async requestProductInfo(productId, projectData) {
    try {
      return await getProductInfo(productId, projectData);
    } catch (productInfo_1) {
      return 'Error at getProductInfo';
    }
  }

  async requestProductInfoValues(productId, projectData) {
    try {
      return await getProductInfoValues(productId, projectData);
    } catch (productInfo_1) {
      return 'Error at getProductInfoValues';
    }
  }

}

export default new ProjectService();
