/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from './NumberInput';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';

const Quantity = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const { handleQuantityRequest } = useDataManager();
  
  return (
    <div>
      <NumberInput
        name="quantity"
        label={t('parameters.quantity')}
        defaultValue={projectData.quantity}
        handleOnBlur={handleQuantityRequest}
      />
    </div>
  );
};

export default Quantity;
