import { resolveProductType } from '../constants/productType';
import RestService from '../services/RestService';
import TokenRestService from '../services/TokenRestService';
import { EXTERNAL_USER } from '../constants/userTypes';

const isTokenService = (userData) => 
  userData && JSON.stringify(userData) !== JSON.stringify({});
const resolveService = (userData) => {
  if (isTokenService(userData)) return TokenRestService;
  return RestService;
}
export const copyTemplate = (id, data, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('POST', `projects/${id}/copy`, data, userData);
}

export const deleteProject = (projectId) =>
  RestService.request('DELETE', `projects/${projectId}`);

// Used when window is closed or reloaded
export const deleteProjectWithBeacon = (projectId) =>
  navigator.sendBeacon(
    `${process.env.REACT_APP_BACK_URL}delete_project/${projectId}`
  );

export const getCost = (projectId, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('GET', `projects/cost/${projectId}`, null,  userData);

}

export const updateProduct = (productId, data, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('PUT', `productDefinitions/${productId}`, data, userData);
}

export const updateMultipleProducts = (data, userData) => {
  const requestService = resolveService(userData);
  requestService.request('PUT', `productDefinitions/updateMultiple`, data, userData);
}

export const updateSide = (productId, data, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('PUT', `sides/${productId}`, data, userData);
}

export const updateMultipleSides = (data, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('PUT', `sides/updateMultiple`, data, userData);
}

export const updateDrawing = (drawingId, data, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('PUT', `drawings/${drawingId}`, data, userData);
}

export const getPublicIp = () => RestService.request('GET', 'public_ip');

export const getProfileChoices = async (sideId, productType, userData) => {
  const requestService = resolveService(userData);
  const product = isTokenService(userData) ? resolveProductType(productType) : productType;
  let merged;
  if (productType === 'LD'){
    const type = 'railProfile4And2';
    merged = await requestService.request(
      'GET',
      `productDefinitions/side/${sideId}/choices/${product}/parameters.${type}?lang=fi`,
      null,
      userData
    );
  }else {
    const typeA = 'lowerRailProfile';
    const typeB = 'upperRailProfile';
    const responseA = await requestService.request(
      'GET',
      `productDefinitions/side/${sideId}/choices/${product}/parameters.${typeA}?lang=fi`,
      null,
      userData
    );
    const responseB = await requestService.request(
      'GET',
      `productDefinitions/side/${sideId}/choices/${product}/parameters.${typeB}?lang=fi`,
      null,
      userData
    );
    responseB.push(responseA[0]);
    merged = responseB;
  }
  return merged
};

export const getGlassChoices = (sideId, productType, userData) => {
  const requestService = resolveService(userData);
  const product = isTokenService(userData) ? resolveProductType(productType) : productType;
  const type = productType === 'LD' ? 'lower_glass' : 'glass';
  return requestService.request(
    'GET',
    `productDefinitions/side/${sideId}/choices/${product}/parameters.${type}?lang=fi`,
    null,
    userData
  );
};

export const getColorChoices = (sideId, productType, userData) => {
  const requestService = resolveService(userData);
  const product = isTokenService(userData) ? resolveProductType(productType) : productType;
  const type = 'profileColor';
  return requestService.request(
    'GET',
    `productDefinitions/side/${sideId}/choices/${product}/parameters.${type}?lang=fi`,
    null,
    userData
  );
};

export const getSideInfo = (sideId, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('GET',`productDefinitions/side/${sideId}`, null, userData)
}

export const setCustomerInfo = (projectId, data, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('PUT', `projects/${projectId}`, data, userData);
}

export const setProjectAsOrdered = (projectId) => RestService.request('POST', `setProjectOrdered/${projectId}`, null)

export const sendEmail = (data) =>
  RestService.request('POST', `send_mail_user`, data);

export const setProjectAsOrderedToken = (projectId, userData) => 
  TokenRestService.request('POST', `projects/setProjectOrdered/${projectId}`, null, userData);

export const changeOwner = (projectId, userData) => {
  const data = {
    targetUser: userData.user_id
  }
  return TokenRestService.request('PUT', `projects/${projectId}`, data, userData );
}

export const login = (data) => 
  TokenRestService.requestLogin(data);

  // FOR SUMMARY INFO
export const getDrawing = (projectId, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('GET', `drawings/project/${projectId}`, null, userData);
}

export const getDrawingInfo = (drawingId, userData) => {
  const requestService = resolveService(userData);
  return requestService.request('GET', `productDefinitions/drawing/${drawingId}`, null, userData);
}

export const getProductInfo = (productId, projectData) => {
  const requestService = resolveService(projectData.userData);
  const url = `productDefinitions/${productId}/parameterValues?lang=fi`;
  return requestService.request('GET', url, null, projectData.userData);
}

export const getProductInfoValues = (productId, projectData) => {
  const requestService = resolveService(projectData.userData);
  const groups = projectData.userType === EXTERNAL_USER ?
      ['multisideExternal', 'onesideExternal'] :
      ['multiside', 'oneside', 'extra'];
  const groupParam = groups.map((g) => `&group[]=${g}`).join('');
  const url = `productDefinitions/${productId}/parameterValues?lang=fi${groupParam}`;
  return requestService.request('GET', url, null, projectData.userData);
}
