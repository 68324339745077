/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';

const DividerCheck = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const { handleDividerCheckRequest } = useDataManager();

  return (
    <div className="divider-container">
      <label className="divider-check">
        <input
          type="checkbox"
          id="divider-check"
          checked={projectData.dividerCheck}
          value="divider"
          onChange={(event) => handleDividerCheckRequest(event.target.checked)}
        />
        &nbsp;&nbsp;
        {t('parameters.horizontal_divider')}
      </label>
    </div>
  );
};

export default DividerCheck;