import React from 'react';
import ReactLoading from 'react-loading';
import useProjectData from '../providers/ProjectDataProvider/use';

const CieloModel = () => {
  const { projectData } = useProjectData();
  const isLoading = projectData.isLoadingModel || projectData.shareId === null;
  return (
    <>
      {isLoading && (
        <div className="loading-container">
          <div className="loading">
            <ReactLoading type="spin" color="#0080E1" />
          </div>
        </div>
      )}
      {!isLoading && (
        <iframe
          id="cielo-iframe"
          src={`${process.env.REACT_APP_IFRAME_URL}3d.php?s=${projectData.shareId}&loc=3.75%2C6.58%2C-6.53&look=0.00%2C0.00%2C0.00&header=0&controls=0&visualization_options=0&lang=fi`}
          title="Generated 3D Balcony Model"
        />
      )}
    </>
  );
};

export default CieloModel;
