/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React, { useState } from 'react';

const TextInput = ({ name, label, defaultValue, handleOnBlur, placeHolder, className, required, type }) => {
  const [inputRef] = useState(React.createRef());

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  return (
    <div className={className} id={`input_${name}`}>
      { required && (
        <span className='mandatory-field'>*</span>
      )}
      <label htmlFor={name} className="parameter-label">{label}</label>
      <div>
        <input
          id={name}
          className="custom-input form-input"
          defaultValue={defaultValue}
          placeholder={placeHolder}
          name={name}
          type={type}
          onKeyPress={handleKeyPress}
          onBlur={(event) => {
            if (handleOnBlur) handleOnBlur(event.target.value);
          }}
          ref={inputRef}
          required={required}
        />
      </div>
    </div>
  );
};

export default TextInput;
