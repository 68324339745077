/* eslint-disable arrow-body-style */
import React from 'react';
import ParametersService from '../../services/ParametersService';
import DividerCheck from './parameters/DividerCheck';
import Dialog from './parameters/Dialog';
import DividerHeight from './parameters/DividerHeight';
import OpeningHeight from './parameters/OpeningHeight';
import Quantity from './parameters/Quantity';
import ProfileColor from './parameters/ProfileColor';
import Glass from './parameters/Glass';
import useProjectData from '../../providers/ProjectDataProvider/use';
import SideLength from './parameters/SideLength';
import SideAngle from './parameters/SideAngle';
import { EXTERNAL_USER } from '../../constants/userTypes';

const Parameters = () => {
  const { projectData } = useProjectData();
  const getParameter = (parameter) => {
    switch (parameter.name) {
      case 'opening_height_0':
        return <OpeningHeight key={parameter.name} side={projectData.sides[0]} labelName={`parameters.opening_height_${projectData.sides[0].index}`} />;
      case 'opening_height_1':
        return <OpeningHeight key={parameter.name} side={projectData.sides[1]} labelName={`parameters.opening_height_${projectData.sides[1].index}`} />;
      case 'opening_height_2':
        return <OpeningHeight key={parameter.name} side={projectData.sides[2]} labelName={`parameters.opening_height_${projectData.sides[2].index}`} />;
      case 'quantity':
        return <Quantity key={parameter.name} />;
      case 'profile_color':
        return <ProfileColor key={parameter.name} />
      case 'glass':
        return <Glass key={parameter.name} type={parameter.name} />;
      case 'side_length':
        return (
          <div className="col-md-6" key={`side_${parameter.index + 1}_length`}>
            <SideLength
              key={`side_${parameter.index + 1}_length`}
              index={parameter.index}
            />
          </div>
        );
      case 'side_angle':
        return (
          <div className="col-md-6" key={`side_${parameter.index + 1}_angle`}>
            <SideAngle
              key={`side_${parameter.index + 1}_angle`}
              index={parameter.index}
            />
          </div>
          
        );
      case 'lower_glass':
        return (
          <div className="col-md-6" key={parameter.name}>
            <Glass type={parameter.name} />  
          </div>         
        );
      case 'upper_glass':
        return (
          <div className="col-md-6" key={parameter.name}>
            <Glass key={parameter.name} type={parameter.name} />  
          </div>
                    
        );
      case 'divider_check':
        return (
          <div className="col-md-6" key={parameter.name}>
            <DividerCheck key={parameter.name} />  
          </div>
        );
      case 'divider_height':
        return (
          <div className="col-md-6" key={parameter.name}>
            <DividerHeight key={parameter.name} />  
          </div>            
        );
      case 'dialog_cielo':
        return <Dialog key={parameter.name} />;
      case 'void':
        return <div className="extra-empty-parameter" key={`extra-empty-parameter_${parameter.index + 1}`} />;
      case 'clear':
        return <div className="clean" key={`${parameter.index + 1}clean_div`}>&nbsp;</div>;
      default:
        return null;
    }
  };

  const GeneralParametersComponents = () => {
    return(
      <div className="row">
        {(projectData.currentProduct.id === 'LD' && projectData.userType !== EXTERNAL_USER) && (
          <div className='row no-margin' key="side_height_container">
            {projectData.sides.map((side, index) => (
              <OpeningHeight
                key={`side_${index + 1}_height`}
                side={side}
                nameClass="col-md-6"
                labelName={`parameters.opening_height_${side.index}`}
              />
            ))}
            <div className="col-md-6"><Quantity /></div>
          </div>
        )}
        {(projectData.currentProduct.id !== 'LD' || projectData.userType === EXTERNAL_USER) && (
          <>
            <div className="col-md-6"><OpeningHeight key={`side_${projectData.sides[0].index + 1}_height`} side={projectData.sides[0]} labelName="parameters.opening_height" /></div>   
            <div className="col-md-6"><Quantity /></div>
          </>
        )}        
        <div className="col-md-12"><ProfileColor /></div>
        <div className="col-md-12"><Glass type="glass" /></div>
      </div>
    )
  }

  const sideParameters = ParametersService.getSideParameters(
    projectData.currentProduct,
    projectData.currentTemplate
  );
  const sideParametersComponents = sideParameters.map((parameter) =>
    getParameter(parameter)
  );

  return (
    <>
      <div className="parameters-container"><GeneralParametersComponents /></div>
      <div className="parameters-container">
        <div className="row">
          {sideParametersComponents}
          {projectData.sides.map((side, index) => (
            <div className="col-md-6" key={`side_${index + 1}_length`}>
              <SideLength
                key={`side_${index + 1}_length`}
                side={side}
              />
            </div>
          ))}
          {projectData.currentProduct.id !== 'LD' && (
            <div className="extra-empty-parameter" />
          )}
        </div>        
      </div>
      <Dialog />
    </>    
  );
};

export default Parameters;
