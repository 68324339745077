/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from './NumberInput';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';
import { LD_MAX_HEIGHT, PL_MAX_HEIGHT, TL_MAX_HEIGHT, LD_MIN_HEIGHT, PL_MIN_HEIGHT, TL_MIN_HEIGHT } from '../../../constants/validations';

const OpeningHeight = ({ side, nameClass, labelName }) => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const [currentHeight, setCurrentHeight] = useState(side.height);
  const { handleOpeningHeightRequest } = useDataManager();
  const [errorValue, setErrorValue] = useState('');
  const [error, setError] = useState(false);

  const productId = useMemo(() => {
    return projectData.currentProduct.id
  }, [projectData.currentProduct.id]);

  const handleChange = (value) => {
    setCurrentHeight(value); 
    if (side.height !== value) {
      handleOpeningHeightRequest(value, side, productId);
    }
  }

  useEffect(() => {
    let tempError = false;
    switch(productId) {
      case 'PL':
        if (currentHeight > PL_MAX_HEIGHT) {
          setErrorValue(t('warnings.greater_than', { number: PL_MAX_HEIGHT }));
          tempError = true;
        }
        if (currentHeight < PL_MIN_HEIGHT) {
          setErrorValue(t('warnings.less_than', { number: PL_MIN_HEIGHT }));
          tempError = true;
        }
        break;
      case 'LD':
        if (currentHeight > LD_MAX_HEIGHT) {
          setErrorValue(t('warnings.greater_than', { number: LD_MAX_HEIGHT }));
          tempError = true;
        }
        if (currentHeight < LD_MIN_HEIGHT) {
          setErrorValue(t('warnings.less_than', { number: LD_MIN_HEIGHT }));
          tempError = true;
        }
        break;
      case 'TL':
        if (currentHeight > TL_MAX_HEIGHT) {
          setErrorValue(t('warnings.greater_than', { number: TL_MAX_HEIGHT }));
          tempError = true;
        }
        if (currentHeight < TL_MIN_HEIGHT) {
          setErrorValue(t('warnings.less_than', { number: TL_MIN_HEIGHT }));
          tempError = true;
        }
        break;
      default:
        tempError = false;
        break;        
    }
    setError(tempError);
    return false;
  }, [currentHeight]);

  return (
    <div className={nameClass}>
      <NumberInput
        name={`opening_height_${side.index}`}
        label={t(labelName)}
        defaultValue={side.height}
        handleOnBlur={(value) => handleChange(value)}
        error={error}
        errorValue={errorValue}
      />
    </div>
  );
};

export default OpeningHeight;
