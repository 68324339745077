import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Cost from '../../components/Cost';
import { DISTRIBUTOR_USER } from '../../constants/userTypes';
import { NIKA_EMAIL } from '../../constants/emailAddress';
import useProjectData from '../../providers/ProjectDataProvider/use';
import ProjectService from '../../services/ProjectService';
import './summary.css';
import CustomAlert from '../../components/CustomAlert';

const Summary = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const customerData = ProjectService.getCustomerData(projectData);
  const productParameters = ProjectService.getParametersData(projectData);
  const distributorParameters = ProjectService.getSpecialParametersData(
    projectData
  );
  const resolveDataText = (data) => {
    if (typeof data === "boolean") {
      return data ? t('yes') : t('no');
    }
    return data ?? '';
  }
  const CustomerInfo = () => Object.keys(customerData).map(data => {
    const name = data.split(/(?=[A-Z])/).join('_').toLowerCase();
    return (
      <div className="col-md-6 col-12" key={`ci_div_${name}`}>
        <label className="col-md-4 col-5 label" key={`ci_label_${name}`}>{t(`customer_info.${name}`)}</label>
        <span className="col-md-8 col-7 element" key={`ci_span_${name}`}>{resolveDataText(customerData[data])}</span>
      </div>
      );
    });

  const SummaryParameters = () => productParameters.map((param) => {
    const typeBool = typeof param.content === 'boolean';
    let value = param.content;
    const classBold = (param.name.indexOf('side') >= 0 && param.name.indexOf('separator') >= 0) ? "no-content-title font-weight-bold" : "";
    if (typeBool) value = param.content ? t('yes') : t('no');
    
    return (
      <>
        <div className="col-12" key={`div_${param.id}`}>
          <label className={`${classBold} label col-md-4 col-5`} key={`label_${param.id}`}>
            {t(`summary_parameters.${param.name}`, param.name)}
          </label>
          <span className="col-md-8 col-7 element" key={`span_${param.id}`}>{value}</span>
        </div>
        <div className="clean" key={`divv_${param.id}`} />
      </>  
    )
  });
  
  const [ isOpen, setIsOpen ] = useState(false);

  const handleModalState = () => (isOpen)

  const handleClosingModal = () => {
    setIsOpen(false);
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <h2>Lasituksesi yhteenveto</h2>
          {projectData.userType !== DISTRIBUTOR_USER && (
            <p>
              {t('summary_description')}        
            </p>
          )}  
          {projectData.userType === DISTRIBUTOR_USER && (
            <p>
              {t('summary_description_auth')}
              <a href={`mailto:${NIKA_EMAIL}`}>
                { NIKA_EMAIL }
              </a>        
            </p>
          )}  
          <p className="orange-text">
            Lue huolellisesti ennen tietojen lähettämistä
          </p>
        </div>
      </div>
      <div className='row'>
        <div className="col-lg-12 col-md-12 col-12">
          <div className="row">
            <CustomerInfo />
          </div>
        </div>        
      </div>
      <div id="cost-small">
        <Cost />
        {projectData.userType !== DISTRIBUTOR_USER && (
          <p className="cost-notes cost-notes-summary">{t('cost_notes')}</p>
        )}
      </div>
      <hr className="line-container col-12" />
      <div className="row">
        <div className='col-lg-6 col-md-6 col-12' id='summary'>
          <h3 className='col-12'>Kokoonpano</h3>
          <div className='clean' />
          <SummaryParameters />
          {projectData.userType === DISTRIBUTOR_USER && (
            <>
              <div className='clean' />
              <h3 className='col-12'>Lisävalinnat</h3>
              <div className='clean' />
              {Object.keys(distributorParameters).map((parameter) => {
                const name = parameter
                  .split(/(?=[A-Z])/)
                  .join('_')
                  .toLowerCase();
                if (distributorParameters[parameter] !== null) {
                  return (
                    <>
                      <div className="col-12" key={`div_${name}_${parameter}`}>
                        <label className="label col-md-4 col-5" key={`label_${name}_${parameter}`}>
                          {parameter}
                        </label>
                        <span className="element col-md-8 col-7" key={`span_${name}_${parameter}`}>
                          {distributorParameters[parameter]}
                        </span>
                      </div>
                      <div className="clean" key={`divv_${name}_${parameter}`} />
                    </>
                  );
                }
                return '';
              })}
            </>
          )}
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <h3>Lisätiedot</h3>
          <p id="additionalNotesContainter" className='text-justify'>{projectData.drawingData.parameters.UserComments}</p>
          <div id="cost-normal">
            <Cost />
            {projectData.userType !== DISTRIBUTOR_USER && (
              <p className="cost-notes cost-notes-summary">{t('cost_notes')}</p>
            )}
          </div>
          {projectData.attachedFile.length > 0 && (
            <div id="attached-data">
              <label className="font-weight-bold label" key="label_attached_imagen">
                {t('uploaded_image')}
              </label>
              <div className='uploaded-image col-12'>
                <button type='button' onClick={() => setIsOpen(true)}>
                  <img id="attached-image" src={projectData.attachedFile} alt='' />
                </button>
              </div>
              <CustomAlert type='image-preview' message={projectData.attachedFile} closeModal={handleClosingModal} modalIsOpen={handleModalState} />              
            </div>
          )}
        </div>
        <div className='clean' />
      </div>
    </div>
  );
};

export default Summary;
