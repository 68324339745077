/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';
import { login } from '../../api';
import './login.css';
import { DISTRIBUTOR_USER } from '../../constants/userTypes';

const LoginModal = ({ handleClosingModal }) => {
  const { t } = useTranslation('common');
  const { dispatchProjectData } = useProjectData();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const authenticate = async () => {
    const credentials = {
      username,
      password,
    };
    try {
      const user = await login(credentials);

      dispatchProjectData({ type: 'user_data', payload: user });
      dispatchProjectData({ type: 'user_type', payload: DISTRIBUTOR_USER });
      setHasError(false);
      setErrorMessage('');
      return true;
    } catch (errorResponse) {
      const errorJson = await errorResponse.json();
      const message =
          errorJson.error.message !== ''
              ? errorJson.error.message
              : 'The request has failed';
      setErrorMessage(message);
      setHasError(true);
      return false;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await authenticate();
    if (response !== false) {
      handleClosingModal();
    }
  };

  return (
    <div className="modal--background">
      <form className="login-container" onSubmit={handleLogin}>
        <button
          type="button"
          className="close-button"
          onClick={handleClosingModal}
        >
          <span>X</span>
        </button>
        <div className="login">{t('login')}</div>
        <input
          className="credentials username"
          type="text"
          placeholder={t('username')}
          onChange={(e) => setUserName(e.target.value)}
          required
        />

        <input
          className="credentials password"
          type="password"
          placeholder={t('password')}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit" className="login-modal-button">
          {t('login')}
        </button>
        {hasError && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default LoginModal;
