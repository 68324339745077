import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import './custom_alert.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'none',
    borderRadius: '0px',
    padding: '0px'
  },

  overlay: {
    zIndex: 20,
  },
};

Modal.setAppElement('#root');

const CustomAlert = ({ type, message, closeModal, modalIsOpen, isAccepted }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Modal isOpen={modalIsOpen()} style={customStyles}>
        <button
          className="close-button"
          type="button"
          onClick={closeModal}
          style={{ float: 'right' }}
          label='Close'
        />
        <div className='content-alert'>
          {type === 'message' && (
            <div className='font-title content-message'>
              <h3>{message}</h3>
              <div className='break' />
              <button className='button-message' type='button' onClick={closeModal}>{t('close-modal')}</button>
            </div>
          )}
          {type === 'confirm' && (
            <div className='font-title content-confirm'>
              <p className='font-title text-center'>{message}</p>
              <div className='break' />
              <button className='button-alert' type='button' onClick={closeModal}>{t('no')}</button>
              &nbsp;&nbsp;
              <button className='button-alert' type='button' onClick={isAccepted}>{t('yes')}</button>
            </div>
          )}
          {type === 'image-preview' && (
            <div className='content-image'>
              <img className='image-preview-alert' src={message} alt={type} />
              <div className='break' />
              <button className='button-general' type='button' onClick={closeModal}>{t('close-modal')}</button>
            </div>
          )}
        </div>      
      </Modal>
    </>
  )};

export default CustomAlert;
