/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from 'react';
import ProjectDataContext from './context';
import initialStateProjectData from './initialState';
import RalColors from '../../data/RalColors';
import {
  getPublicIp,
  copyTemplate,
  getGlassChoices,
  getColorChoices,
  getProfileChoices,
  deleteProject,
  getCost,
} from '../../api';
import ProjectService from '../../services/ProjectService';
import reducer from './reducer';

let LAST_REQUEST = 0;

const ProjectDataProvider = ({ children }) => {
  const [projectData, dispatchProjectData] = useReducer(
    reducer,
    initialStateProjectData
  );
  const [copiedProject, setCopiedProject] = useState(null);

  const copyProject = (lastRequest) => {
    if (projectData.projectId !== null) deleteProject(projectData.projectId);
    dispatchProjectData({ type: 'clear_project' });
    const projectName = ProjectService.getProjectName(
      projectData.userIp,
      projectData.date,
      projectData.currentProduct,
      projectData.currentTemplate
    );
    copyTemplate(projectData.currentTemplate.id, {
      newName: projectName,
      customerName: '',
    }, projectData.userData)
      .then((data) => {
        console.log('copyProject response =>', data);
        setCopiedProject({ ...data, lastRequest });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setCustomerInfo = (type, value) => {
    if (value.length > 0) {
      dispatchProjectData({
        type,
        payload: value,
      });
    }
  };

  const setProfiles = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    getProfileChoices(sides.result[0].id, projectData.currentProduct.id, projectData.userData)
      .then((profiles) => {
        dispatchProjectData({
          type: 'profile_options',
          payload: profiles,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatchProjectData({type: 'remove_user_data'});
          window.location.reload();
        }
        console.error(error);
      });
  }

  const setGlasses = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    getGlassChoices(sides.result[0].id, projectData.currentProduct.id, projectData.userData)
      .then((glasses) => {
        dispatchProjectData({
          type: 'full_glasses',
          payload: glasses,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatchProjectData({type: 'remove_user_data'});
          window.location.reload();
        }
        console.error(error);
      });
  };

  const addHexColors = (colors) => {
    colors.map((color) => {
      const element = RalColors.colors.filter(
        (rcol) => rcol.name === color.value
      );
      const hex = element ? element[0].hex : 'F6F6F6';
      color.hex = hex;
      return color;
    });
    return colors;
  };

  const setProfileColors = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    getColorChoices(sides.result[0].id, projectData.currentProduct.id, projectData.userData)
    .then((colors) => {
        const newColors = addHexColors(colors);
        dispatchProjectData({
          type: 'color_options',
          payload: { choices: newColors },
        });
      })
      .catch((error) => {
        const colors = RalColors.colors.map((c) => {
          c.label = c.name;
          return c;
        })
        dispatchProjectData({
          type: 'color_options',
          payload: { choices: colors },
        });
        console.error('profile colors error ',error);
      });
  };

  const handleProjectData = () => {
    if (copiedProject.lastRequest !== LAST_REQUEST) return;

    const projectInfo = ProjectService.getProjectInfo(copiedProject);
    projectInfo.forEach((item) => {
      dispatchProjectData(item);
    });
    dispatchProjectData({ type: 'loading_model', payload: false });
  };

  useEffect(() => {
    getPublicIp()
      .then((data) => {
        dispatchProjectData({ type: 'user_ip', payload: data.ip });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (projectData.projectId === null) return;
    getCost(projectData.projectId, projectData.userData)
      .then((data) => {
        const { price, priceWithVat } = data;
        const currentPrice =
          projectData.userType === 'distributor' ? price : priceWithVat;
        dispatchProjectData({
          type: 'project_cost',
          payload: parseFloat(currentPrice),
        });
        dispatchProjectData({
          type: 'loading_cost',
          payload: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatchProjectData({type: 'remove_user_data'});
          window.location.reload();
        }
        console.error(error);
      });
  }, [projectData.projectId, projectData.userType, projectData.quantity]);

  useEffect(() => {
    if (projectData.currentProduct === null) return;
    const template = ProjectService.getDefaultTemplateFromProduct(
      projectData.currentProduct
    );
    dispatchProjectData({ type: 'current_template', payload: template });
  }, [projectData.currentProduct]);

  useEffect(() => {
    if (projectData.userIp !== null && projectData.currentTemplate !== null) {
      LAST_REQUEST += 1;
      dispatchProjectData({ type: 'loading_model', payload: true });
      dispatchProjectData({ type: 'loading_cost', payload: true });
      copyProject(LAST_REQUEST);
    }
  }, [projectData.userIp, projectData.currentTemplate]);

  useEffect(() => {
    if (copiedProject === null) return;
    
    handleProjectData();
    setGlasses();
    setProfileColors();
    setProfiles();
  }, [copiedProject]);

  useEffect(() => {
    if (
      projectData.fullGlasses !== null &&
      LAST_REQUEST === copiedProject.lastRequest
    )
      handleProjectData();
  }, [projectData.fullGlasses]);

  return (
    <ProjectDataContext.Provider
      value={{
        projectData,
        dispatchProjectData,
        setCustomerInfo,
      }}
    >
      {children}
    </ProjectDataContext.Provider>
  );
};

export default ProjectDataProvider;
