/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';
import { resolveProductTypeName } from '../../constants/productType';
import products from '../../data/Products';
import Product from './Product';

import './product_selection.css';

const ModelSelection = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const userID = projectData.userData.user_id ?? 0;
  const productItems = products.map(({ name, id }) => (
    <Product
      key={id}
      productId={id}
      productName={t(`product_selection.${name}`)}
      description={t(`product_description.${name}`)}
      productDisabled={(userID === 505 || userID === 549) && resolveProductTypeName(name)!=='LD'}
    />
  ));

  return (
    <> 
      <div className="products-description">{t('product_selection.description')}</div>
      <div className="choose-products text-center font-semibold">{t('product_selection.choose_product')}</div>
      <div className="products-container">{productItems}</div>
    </>
  );
};

export default ModelSelection;
