export const MIN_SYSTEM_HEIGHT = 1800;
export const MAX_SYSTEM_HEIGHT = 2700;
export const MAX_SYSTEM_WIDTH = 3200;
export const MIN_DOOR_WIDTH = 500;
export const MAX_DOOR_WIDTH = 1200;
export const MIN_DOOR_HEIGHT = 1900;
export const MAX_DOOR_HEIGHT = 2400;
export const MIN_GLASS_WIDTH = 200;
export const MAX_GLASS_WDITH = 2000;
export const MIN_UPPER_GLASS_HEIGHT = 200;
export const MIN_WALL_HEIGHT = 1150;
export const MAX_WALL_HEIGHT = 1500;
export const PL_MAX_WIDTH = 10800;
export const PL_MAX_HEIGHT = 2200;
export const TL_MAX_WIDTH = 10800;
export const TL_MAX_HEIGHT = 3000;
export const LD_MAX_WIDTH = 8000;
export const LD_MAX_HEIGHT = 3000;
export const PL_MIN_WIDTH = 400;
export const PL_MIN_HEIGHT = 500;
export const TL_MIN_WIDTH = 400;
export const TL_MIN_HEIGHT = 500;
export const LD_MIN_WIDTH = 800;
export const LD_MIN_HEIGHT = 500;
export const MAX_FILE_SIZE = 5;