export default function resolveGlassDepth(product, height, check=false) {
  switch (product) {
    case 'PL':
      return balconyGlassDepthOptions(height);
    case 'TL':
      return terraceGlassDepthOptions(height);
    case 'LD':
      return slidingGlassDepthOptions(height, check);
    default:
      throw new Error ("product type doesn't exist");
  }
}

export const filterGlasses = (glasses, projectData, dividerCheck) => {
  const depth = resolveGlassDepth(projectData.currentProduct.id, projectData.openingHeight, dividerCheck);
  const options = glasses.filter(glass => depth === getGlassDepth(glass));
  return options;
};


const balconyGlassDepthOptions = (height) => {
  if (height > 1800) {
    return 10;
  } if (height > 1600 && height <= 1800) {
    return 8;
  }
    return 6;
  
}

const terraceGlassDepthOptions = (height) => {
  if (height > 2700) {
    return 10;
  } 
    return 8;
}

const slidingGlassDepthOptions = (height, check = false) => {
  if (height > 2700 && check) {
    return 6;
  } if (height > 2300 && !check) {
    return 6;
  } if (height > 2300 &&  height <= 2700 && check) {
    return 4;
  } if (height <= 2300) {
    return 4
  }
  return undefined;
}

const getGlassDepth = (glass) => {
  const depth = glass.code.match(/\d+/);
  if (depth) return parseInt(depth[0]);
  return 0;
}

