/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import ProjectService from '../../../services/ProjectService'; 

const ProfileColor = () => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const { handleProfileColorRequest } = useDataManager();
  const [ defaultColor, setDefaultColor ] = useState(
    "RAL7024 (Graphite grey) STANDARD COLOR"
  );

  const colorsList = useMemo(() => {
    if (projectData.colorOptions === null) return null;
    return projectData.colorOptions.choices.map((color) => ({
      value: color.label,
      label: (
        <div className="color-container">
          <div
            className="color-box"
            style={{
              background: `#${color.hex}`,
              verticalAlign: 'middle',
            }}
          />
          <div className="color-name">{color.label}</div>
        </div>
      ),
    }))
  }, [projectData.colorOptions]);

  useEffect(() => {
    let isMounted = true;
    
    let color = projectData.profileColor;
    ProjectService.requestSideInfo(projectData.sides[0].id, projectData)
    .then((data) => {
      if(isMounted) {
        try {
          color = data[0].color;
        } catch (e) {
          console.log("Profile Color error: ", e);
        }
        setDefaultColor(color); 
      }
    });
   return () => {
     isMounted = false;
   };
 }, []);

  const chosenColor = useMemo(()=>{
    let color = colorsList.find(
      (color) => color.value === projectData.profileColor
    );
    if (!color || defaultColor !== projectData.profileColor) {
      color = colorsList.find(
        (color) => color.value === defaultColor
      );
    }
    return color
  },[projectData.profileColor, colorsList, defaultColor])

  return (
    <div>
      <label className="parameter-label">{t('parameters.profile_color')}</label>
      {projectData.profileColor === null && <LoadingPlaceholder />}
      {projectData.profileColor !== null && (
        <Select
          options={colorsList}
          value={chosenColor}
          isSearchable
          onChange={handleProfileColorRequest}
          placeholder={`${t('select')}...`}
        />
      )}
    </div>
  );
};

export default ProfileColor;
