/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from './NumberInput';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';
import { LD_MAX_WIDTH, PL_MAX_WIDTH, TL_MAX_WIDTH, LD_MIN_WIDTH, PL_MIN_WIDTH, TL_MIN_WIDTH } from '../../../constants/validations';

const SideLength = ({ side }) => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const [ currentLength, setCurrentLength ] = useState(side.length);
  const { handleSideLengthRequest } = useDataManager();
  const [errorValue, setErrorValue] = useState('');
  const [error, setError] = useState(false);

  const productId = useMemo(() => {
    return projectData.currentProduct.id;
  }, [projectData.currentProduct.id]);

  const handleChange = (value) => {
    setCurrentLength(value); 
    if (side.length !== value) {
      handleSideLengthRequest(value, side, productId);
    }
  }
  useEffect(() => {
    let tempError = false;
    switch(productId) {
      case 'PL':
        if (currentLength > PL_MAX_WIDTH) {
          setErrorValue(t('warnings.greater_than', { number: PL_MAX_WIDTH }));
          tempError = true;
        }
        if (currentLength < PL_MIN_WIDTH) {
          setErrorValue(t('warnings.less_than', { number: PL_MIN_WIDTH }));
          tempError = true;
        }
        break;
      case 'LD':
        if (currentLength > LD_MAX_WIDTH) {
          setErrorValue(t('warnings.greater_than', { number: LD_MAX_WIDTH }));
          tempError = true;
        }
        if (currentLength < LD_MIN_WIDTH) {
          setErrorValue(t('warnings.less_than', { number: LD_MIN_WIDTH }));
          tempError = true;
        }
        break;
      case 'TL':
        if (currentLength > TL_MAX_WIDTH) {
          setErrorValue(t('warnings.greater_than', { number: TL_MAX_WIDTH }));
          tempError = true;
        }
        if (currentLength < TL_MIN_WIDTH) {
          setErrorValue(t('warnings.less_than', { number: TL_MIN_WIDTH }));
          tempError = true;
        }
        break;
      default:
        tempError = false;
        break;        
    }
    setError(tempError);
  }, [currentLength, productId, projectData.sides, side.id, t]);

  return (
    <NumberInput
      name={`side_${side.index + 1}_length`}
      label={t('parameters.side_length', { n: side.index + 1})}
      defaultValue={currentLength}
      handleOnBlur={(value) => handleChange(value)}
      error={error}
      errorValue={errorValue}
    />
  );
};

export default SideLength;
