export const scrollToBottom = (elementId) => {
  const el = document.getElementById(elementId);
  el.scrollTop = el.scrollHeight;
  window.scroll(0, 10000);
};

export const getCurrentDate = () => {
  const date = new Date();
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};
