/* eslint-disable react/no-array-index-key */
import './parameters.css';
import React, { useState } from 'react';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';

const NumberInput = ({
  name,
  label,
  defaultValue,
  handleOnBlur,
  disabled,
  error,
  errorValue
}) => {
  const [inputRef] = useState(React.createRef());
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const Message = () => (
    <div className="feedback-message">
      { errorValue }
    </div>
  );

  return (
    <div>
      <label className="parameter-label">{label}</label>
      {defaultValue === null && <LoadingPlaceholder />}
      {defaultValue !== null && (
        <>
          <div className={`${error ? 'invalid-input' : ''}`}>
            <input
              disabled={disabled}
              className="custom-input"
              type="number"
              defaultValue={defaultValue}
              name={name}
              onKeyPress={handleKeyPress}
              onBlur={(event) => {
                if (handleOnBlur) handleOnBlur(parseInt(event.target.value));
              }}
              ref={inputRef}
            />
          </div>
          {error && <Message />}
        </>
      )}
    </div>
  );
};

NumberInput.defaultProps = {
  disabled: false,
};

export default NumberInput;
