import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import App from './App';
import ProjectDataProvider from './providers/ProjectDataProvider/provider';
import DataManagerProvider from './providers/DataManagerProvider/provider';
import common_fi from './translations/fi/common.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'fi',
  resources: {
    fi: {
      common: common_fi,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ProjectDataProvider>
      <DataManagerProvider>
        <App />
      </DataManagerProvider>
    </ProjectDataProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
