import { SECTION_PRODUCT_SELECTION } from '../../constants/sections';
import { DISTRIBUTOR_USER, EXTERNAL_USER } from '../../constants/userTypes';
import { getCurrentDate } from '../../utils';

const initialStateProjectData = {
  projectName: null,
  userType: localStorage.getItem('userData') ? DISTRIBUTOR_USER : EXTERNAL_USER,
  userIp: null,
  userData: JSON.parse(localStorage.getItem('userData')) || {},
  date: getCurrentDate(),
  shareId: null,
  projectId: null,
  drawingId: null,
  drawingData: null,
  validationError: false,
  currentSection: SECTION_PRODUCT_SELECTION,
  currentSide: {
    id: 1,
    productId: 1
  },
  additionalNotes: null,
  pendingRequests: null,
  isLoadingModel: true,
  isLoadingCost: true,
  currentProduct: null,
  currentTemplate: null,
  attachedFile: '',

  // Parameters from configurator
  fullGlasses: null,
  glassOptions: null,
  profileOptions: null,
  openingHeight: null,
  dividerHeight: null,
  dividerCheck: false,
  quantity: null,
  lockType: null,
  profileColor: null,
  colorOptions: null,
  glass: null,
  lowerGlass: null,
  upperGlass: null,
  sides: null,
  currentLength: 0,
  
  // Distributor parameters (not all products have all these 6)
  glassMouldings: null,
  s1Lists: null,
  perforationUpperProfile: null,
  perforationLowerProfile: null,
  drainingHoles: null,
  topSeal: null,
  deliveredInParts: false,

  // Customer info
  firstName: null,
  lastName: null,
  customerAddress: null,
  customerCity: null,
  customerCountry: null,
  customerPhone: null,
  customerPostalCode: null,
  customerEmail: null,
  deliverToDistributor: false,
  deliveryChoice: 1
};

export default initialStateProjectData;