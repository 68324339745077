const RalColors = {
  colors: [
    { code: "RAL9016", name: "RAL9016 (Traffic white) STANDARD COLOR", hex: "F6F6F6" },
    { code: "RAL9006", name: "RAL9006 (White aluminium) STANDARD COLOR", hex: "A5A5A5" },
    { code: "RAL7024", name: "RAL7024 (Graphite grey) STANDARD COLOR", hex: "474A51" },
    { code: "RAL1000", name: "RAL1000 (Green beige)", hex: "BEBD7F" },
    { code: "RAL1001", name: "RAL1001 (Beige)", hex: "C2B078" },
    { code: "RAL1002", name: "RAL1002 (Sand yellow)", hex: "C6A664" },
    { code: "RAL1003", name: "RAL1003 (Signal yellow)", hex: "E5BE01" },
    { code: "RAL1004", name: "RAL1004 (Golden yellow)", hex: "CDA434" },
    { code: "RAL1005", name: "RAL1005 (Honey yellow)", hex: "A98307" },
    { code: "RAL1006", name: "RAL1006 (Maize yellow)", hex: "E4A010" },
    { code: "RAL1007", name: "RAL1007 (Daffodil yellow)", hex: "DC9D00" },
    { code: "RAL1011", name: "RAL1011 (Brown beige)", hex: "8A6642" },
    { code: "RAL1012", name: "RAL1012 (Lemon yellow)", hex: "C7B446" },
    { code: "RAL1013", name: "RAL1013 (Oyster white)", hex: "EAE6CA" },
    { code: "RAL1014", name: "RAL1014 (Ivory)", hex: "E1CC4F" },
    { code: "RAL1015", name: "RAL1015 (Light ivory)", hex: "E6D690" },
    { code: "RAL1016", name: "RAL1016 (Sulfur yellow)", hex: "EDFF21" },
    { code: "RAL1017", name: "RAL1017 (Saffron yellow)", hex: "F5D033" },
    { code: "RAL1018", name: "RAL1018 (Zinc yellow)", hex: "F8F32B" },
    { code: "RAL1019", name: "RAL1019 (Grey beige)", hex: "9E9764" },
    { code: "RAL1020", name: "RAL1020 (Olive yellow)", hex: "999950" },
    { code: "RAL1021", name: "RAL1021 (Rape yellow)", hex: "F3DA0B" },
    { code: "RAL1023", name: "RAL1023 (Traffic yellow)", hex: "FAD201" },
    { code: "RAL1024", name: "RAL1024 (Ochre yellow)", hex: "AEA04B" },
    { code: "RAL1026", name: "RAL1026 (Luminous yellow)", hex: "FFFF00" },
    { code: "RAL1027", name: "RAL1027 (Curry)", hex: "9D9101" },
    { code: "RAL1028", name: "RAL1028 (Melon yellow)", hex: "F4A900" },
    { code: "RAL1032", name: "RAL1032 (Broom yellow)", hex: "D6AE01" },
    { code: "RAL1033", name: "RAL1033 (Dahlia yellow)", hex: "F3A505" },
    { code: "RAL1034", name: "RAL1034 (Pastel yellow)", hex: "EFA94A" },
    { code: "RAL1035", name: "RAL1035 (Pearl beige)", hex: "6A5D4D" },
    { code: "RAL1036", name: "RAL1036 (Pearl gold)", hex: "705335" },
    { code: "RAL1037", name: "RAL1037 (Sun yellow)", hex: "F39F18" },
    { code: "RAL2000", name: "RAL2000 (Yellow orange)", hex: "ED760E" },
    { code: "RAL2001", name: "RAL2001 (Red orange)", hex: "C93C20" },
    { code: "RAL2002", name: "RAL2002 (Vermilion)", hex: "CB2821" },
    { code: "RAL2003", name: "RAL2003 (Pastel orange)", hex: "FF7514" },
    { code: "RAL2004", name: "RAL2004 (Pure orange)", hex: "F44611" },
    { code: "RAL2005", name: "RAL2005 (Luminous orange)", hex: "FF2301" },
    {
      code: "RAL2007",
      name: "RAL2007 (Luminous bright orange)",
      hex: "FFA420",
    },
    { code: "RAL2008", name: "RAL2008 (Bright red orange)", hex: "F75E25" },
    { code: "RAL2009", name: "RAL2009 (Traffic orange)", hex: "F54021" },
    { code: "RAL2010", name: "RAL2010 (Signal orange)", hex: "D84B20" },
    { code: "RAL2011", name: "RAL2011 (Deep orange)", hex: "EC7C26" },
    { code: "RAL2012", name: "RAL2012 (Salmon range)", hex: "E55137" },
    { code: "RAL2013", name: "RAL2013 (Pearl orange)", hex: "C35831" },
    { code: "RAL3000", name: "RAL3000 (Flame red)", hex: "AF2B1E" },
    { code: "RAL3001", name: "RAL3001 (Signal red)", hex: "A52019" },
    { code: "RAL3002", name: "RAL3002 (Carmine red)", hex: "A2231D" },
    { code: "RAL3003", name: "RAL3003 (Ruby red)", hex: "9B111E" },
    { code: "RAL3004", name: "RAL3004 (Purple red)", hex: "75151E" },
    { code: "RAL3005", name: "RAL3005 (Wine red)", hex: "5E2129" },
    { code: "RAL3007", name: "RAL3007 (Black red)", hex: "412227" },
    { code: "RAL3009", name: "RAL3009 (Oxide red)", hex: "642424" },
    { code: "RAL3011", name: "RAL3011 (Brown red)", hex: "781F19" },
    { code: "RAL3012", name: "RAL3012 (Beige red)", hex: "C1876B" },
    { code: "RAL3013", name: "RAL3013 (Tomato red)", hex: "A12312" },
    { code: "RAL3014", name: "RAL3014 (Antique pink)", hex: "D36E70" },
    { code: "RAL3015", name: "RAL3015 (Light pink)", hex: "EA899A" },
    { code: "RAL3016", name: "RAL3016 (Coral red)", hex: "B32821" },
    { code: "RAL3017", name: "RAL3017 (Rose)", hex: "E63244" },
    { code: "RAL3018", name: "RAL3018 (Strawberry red)", hex: "D53032" },
    { code: "RAL3020", name: "RAL3020 (Traffic red)", hex: "CC0605" },
    { code: "RAL3022", name: "RAL3022 (Salmon pink)", hex: "D95030" },
    { code: "RAL3024", name: "RAL3024 (Luminous red)", hex: "F80000" },
    { code: "RAL3026", name: "RAL3026 (Luminous bright red)", hex: "FE0000" },
    { code: "RAL3027", name: "RAL3027 (Raspberry red)", hex: "C51D34" },
    { code: "RAL3028", name: "RAL3028 (Pure  red)", hex: "CB3234" },
    { code: "RAL3031", name: "RAL3031 (Orient red)", hex: "B32428" },
    { code: "RAL3032", name: "RAL3032 (Pearl ruby red)", hex: "721422" },
    { code: "RAL3033", name: "RAL3033 (Pearl pink)", hex: "B44C43" },
    { code: "RAL4001", name: "RAL4001 (Red lilac)", hex: "6D3F5B" },
    { code: "RAL4002", name: "RAL4002 (Red violet)", hex: "922B3E" },
    { code: "RAL4003", name: "RAL4003 (Heather violet)", hex: "DE4C8A" },
    { code: "RAL4004", name: "RAL4004 (Claret violet)", hex: "641C34" },
    { code: "RAL4005", name: "RAL4005 (Blue lilac)", hex: "6C4675" },
    { code: "RAL4006", name: "RAL4006 (Traffic purple)", hex: "A03472" },
    { code: "RAL4007", name: "RAL4007 (Purple violet)", hex: "4A192C" },
    { code: "RAL4008", name: "RAL4008 (Signal violet)", hex: "924E7D" },
    { code: "RAL4009", name: "RAL4009 (Pastel violet)", hex: "A18594" },
    { code: "RAL4010", name: "RAL4010 (Telemagenta)", hex: "CF3476" },
    { code: "RAL4011", name: "RAL4011 (Pearl violet)", hex: "8673A1" },
    { code: "RAL4012", name: "RAL4012 (Pearl black berry)", hex: "6C6874" },
    { code: "RAL5000", name: "RAL5000 (Violet blue)", hex: "354D73" },
    { code: "RAL5001", name: "RAL5001 (Green blue)", hex: "1F3438" },
    { code: "RAL5002", name: "RAL5002 (Ultramarine blue)", hex: "20214F" },
    { code: "RAL5003", name: "RAL5003 (Saphire blue)", hex: "1D1E33" },
    { code: "RAL5004", name: "RAL5004 (Black blue)", hex: "18171C" },
    { code: "RAL5005", name: "RAL5005 (Signal blue)", hex: "1E2460" },
    { code: "RAL5007", name: "RAL5007 (Brillant blue)", hex: "3E5F8A" },
    { code: "RAL5008", name: "RAL5008 (Grey blue)", hex: "26252D" },
    { code: "RAL5009", name: "RAL5009 (Azure blue)", hex: "025669" },
    { code: "RAL5010", name: "RAL5010 (Gentian blue)", hex: "0E294B" },
    { code: "RAL5011", name: "RAL5011 (Steel blue)", hex: "231A24" },
    { code: "RAL5012", name: "RAL5012 (Light blue)", hex: "3B83BD" },
    { code: "RAL5013", name: "RAL5013 (Cobalt blue)", hex: "1E213D" },
    { code: "RAL5014", name: "RAL5014 (Pigeon blue)", hex: "606E8C" },
    { code: "RAL5015", name: "RAL5015 (Sky blue)", hex: "2271B3" },
    { code: "RAL5017", name: "RAL5017 (Traffic blue)", hex: "063971" },
    { code: "RAL5018", name: "RAL5018 (Turquoise blue)", hex: "3F888F" },
    { code: "RAL5019", name: "RAL5019 (Capri blue)", hex: "1B5583" },
    { code: "RAL5020", name: "RAL5020 (Ocean blue)", hex: "1D334A" },
    { code: "RAL5021", name: "RAL5021 (Water blue)", hex: "256D7B" },
    { code: "RAL5022", name: "RAL5022 (Night blue)", hex: "252850" },
    { code: "RAL5023", name: "RAL5023 (Distant blue)", hex: "49678D" },
    { code: "RAL5024", name: "RAL5024 (Pastel blue)", hex: "5D9B9B" },
    { code: "RAL5025", name: "RAL5025 (Pearl gentian blue)", hex: "2A6478" },
    { code: "RAL5026", name: "RAL5026 (Pearl night blue)", hex: "102C54" },
    { code: "RAL6000", name: "RAL6000 (Patina green)", hex: "316650" },
    { code: "RAL6001", name: "RAL6001 (Emerald green)", hex: "287233" },
    { code: "RAL6002", name: "RAL6002 (Leaf green)", hex: "2D572C" },
    { code: "RAL6003", name: "RAL6003 (Olive green)", hex: "424632" },
    { code: "RAL6004", name: "RAL6004 (Blue green)", hex: "1F3A3D" },
    { code: "RAL6005", name: "RAL6005 (Moss green)", hex: "2F4538" },
    { code: "RAL6006", name: "RAL6006 (Grey olive)", hex: "3E3B32" },
    { code: "RAL6007", name: "RAL6007 (Bottle green)", hex: "343B29" },
    { code: "RAL6008", name: "RAL6008 (Brown green)", hex: "39352A" },
    { code: "RAL6009", name: "RAL6009 (Fir green)", hex: "31372B" },
    { code: "RAL6010", name: "RAL6010 (Grass green)", hex: "35682D" },
    { code: "RAL6011", name: "RAL6011 (Reseda green)", hex: "587246" },
    { code: "RAL6012", name: "RAL6012 (Black green)", hex: "343E40" },
    { code: "RAL6013", name: "RAL6013 (Reed green)", hex: "6C7156" },
    { code: "RAL6014", name: "RAL6014 (Yellow olive)", hex: "47402E" },
    { code: "RAL6015", name: "RAL6015 (Black olive)", hex: "3B3C36" },
    { code: "RAL6016", name: "RAL6016 (Turquoise green)", hex: "1E5945" },
    { code: "RAL6017", name: "RAL6017 (May green)", hex: "4C9141" },
    { code: "RAL6018", name: "RAL6018 (Yellow green)", hex: "57A639" },
    { code: "RAL6019", name: "RAL6019 (Pastel green)", hex: "BDECB6" },
    { code: "RAL6020", name: "RAL6020 (Chrome green)", hex: "2E3A23" },
    { code: "RAL6021", name: "RAL6021 (Pale green)", hex: "89AC76" },
    { code: "RAL6022", name: "RAL6022 (Olive drab)", hex: "25221B" },
    { code: "RAL6024", name: "RAL6024 (Traffic green)", hex: "308446" },
    { code: "RAL6025", name: "RAL6025 (Fern green)", hex: "3D642D" },
    { code: "RAL6026", name: "RAL6026 (Opal green)", hex: "015D52" },
    { code: "RAL6027", name: "RAL6027 (Light green)", hex: "84C3BE" },
    { code: "RAL6028", name: "RAL6028 (Pine green)", hex: "2C5545" },
    { code: "RAL6029", name: "RAL6029 (Mint green)", hex: "20603D" },
    { code: "RAL6032", name: "RAL6032 (Signal green)", hex: "317F43" },
    { code: "RAL6033", name: "RAL6033 (Mint turquoise)", hex: "497E76" },
    { code: "RAL6034", name: "RAL6034 (Pastel turquoise)", hex: "7FB5B5" },
    { code: "RAL6035", name: "RAL6035 (Pearl green)", hex: "1C542D" },
    { code: "RAL6036", name: "RAL6036 (Pearl opal green)", hex: "193737" },
    { code: "RAL6037", name: "RAL6037 (Pure green)", hex: "008F39" },
    { code: "RAL6038", name: "RAL6038 (Luminous green)", hex: "00BB2D" },
    { code: "RAL7000", name: "RAL7000 (Squirrel grey)", hex: "78858B" },
    { code: "RAL7001", name: "RAL7001 (Silver grey)", hex: "8A9597" },
    { code: "RAL7002", name: "RAL7002 (Olive grey)", hex: "7E7B52" },
    { code: "RAL7003", name: "RAL7003 (Moss grey)", hex: "6C7059" },
    { code: "RAL7004", name: "RAL7004 (Signal grey)", hex: "969992" },
    { code: "RAL7005", name: "RAL7005 (Mouse grey)", hex: "646B63" },
    { code: "RAL7006", name: "RAL7006 (Beige grey)", hex: "6D6552" },
    { code: "RAL7008", name: "RAL7008 (Khaki grey)", hex: "6A5F31" },
    { code: "RAL7009", name: "RAL7009 (Green grey)", hex: "4D5645" },
    { code: "RAL7010", name: "RAL7010 (Tarpaulin grey)", hex: "4C514A" },
    { code: "RAL7011", name: "RAL7011 (Iron grey)", hex: "434B4D" },
    { code: "RAL7012", name: "RAL7012 (Basalt grey)", hex: "4E5754" },
    { code: "RAL7013", name: "RAL7013 (Brown grey)", hex: "464531" },
    { code: "RAL7015", name: "RAL7015 (Slate grey)", hex: "434750" },
    { code: "RAL7016", name: "RAL7016 (Anthracite grey)", hex: "293133" },
    { code: "RAL7021", name: "RAL7021 (Black grey)", hex: "23282B" },
    { code: "RAL7022", name: "RAL7022 (Umbra grey)", hex: "332F2C" },
    { code: "RAL7023", name: "RAL7023 (Concrete grey)", hex: "686C5E" },
    { code: "RAL7024", name: "RAL7024 (Graphite grey)", hex: "474A51" },
    { code: "RAL7026", name: "RAL7026 (Granite grey)", hex: "2F353B" },
    { code: "RAL7030", name: "RAL7030 (Stone grey)", hex: "8B8C7A" },
    { code: "RAL7031", name: "RAL7031 (Blue grey)", hex: "474B4E" },
    { code: "RAL7032", name: "RAL7032 (Pebble grey)", hex: "B8B799" },
    { code: "RAL7033", name: "RAL7033 (Cement grey)", hex: "7D8471" },
    { code: "RAL7034", name: "RAL7034 (Yellow grey)", hex: "8F8B66" },
    { code: "RAL7035", name: "RAL7035 (Light grey)", hex: "D7D7D7" },
    { code: "RAL7036", name: "RAL7036 (Platinum grey)", hex: "7F7679" },
    { code: "RAL7037", name: "RAL7037 (Dusty grey)", hex: "7D7F7D" },
    { code: "RAL7038", name: "RAL7038 (Agate grey)", hex: "B5B8B1" },
    { code: "RAL7039", name: "RAL7039 (Quartz grey)", hex: "6C6960" },
    { code: "RAL7040", name: "RAL7040 (Window grey)", hex: "9DA1AA" },
    { code: "RAL7042", name: "RAL7042 (Traffic grey A)", hex: "8D948D" },
    { code: "RAL7043", name: "RAL7043 (Traffic grey B)", hex: "4E5452" },
    { code: "RAL7044", name: "RAL7044 (Silk grey)", hex: "CAC4B0" },
    { code: "RAL7045", name: "RAL7045 (Telegrey 1)", hex: "909090" },
    { code: "RAL7046", name: "RAL7046 (Telegrey 2)", hex: "82898F" },
    { code: "RAL7047", name: "RAL7047 (Telegrey 4)", hex: "D0D0D0" },
    { code: "RAL7048", name: "RAL7048 (Pearl mouse grey)", hex: "898176" },
    { code: "RAL8000", name: "RAL8000 (Green brown)", hex: "826C34" },
    { code: "RAL8001", name: "RAL8001 (Ochre brown)", hex: "955F20" },
    { code: "RAL8002", name: "RAL8002 (Signal brown)", hex: "6C3B2A" },
    { code: "RAL8003", name: "RAL8003 (Clay brown)", hex: "734222" },
    { code: "RAL8004", name: "RAL8004 (Copper brown)", hex: "8E402A" },
    { code: "RAL8007", name: "RAL8007 (Fawn brown)", hex: "59351F" },
    { code: "RAL8008", name: "RAL8008 (Olive brown)", hex: "6F4F28" },
    { code: "RAL8011", name: "RAL8011 (Nut brown)", hex: "5B3A29" },
    { code: "RAL8012", name: "RAL8012 (Red brown)", hex: "592321" },
    { code: "RAL8014", name: "RAL8014 (Sepia brown)", hex: "382C1E" },
    { code: "RAL8015", name: "RAL8015 (Chestnut brown)", hex: "633A34" },
    { code: "RAL8016", name: "RAL8016 (Mahogany brown)", hex: "4C2F27" },
    { code: "RAL8017", name: "RAL8017 (Chocolate brown)", hex: "45322E" },
    { code: "RAL8019", name: "RAL8019 (Grey brown)", hex: "403A3A" },
    { code: "RAL8022", name: "RAL8022 (Black brown)", hex: "212121" },
    { code: "RAL8023", name: "RAL8023 (Orange brown)", hex: "A65E2E" },
    { code: "RAL8024", name: "RAL8024 (Beige brown)", hex: "79553D" },
    { code: "RAL8025", name: "RAL8025 (Pale brown)", hex: "755C48" },
    { code: "RAL8028", name: "RAL8028 (Terra brown)", hex: "4E3B31" },
    { code: "RAL8029", name: "RAL8029 (Pearl copper)", hex: "763C28" },
    { code: "RAL9001", name: "RAL9001 (Cream)", hex: "FDF4E3" },
    { code: "RAL9002", name: "RAL9002 (Grey white)", hex: "E7EBDA" },
    { code: "RAL9003", name: "RAL9003 (Signal white)", hex: "F4F4F4" },
    { code: "RAL9004", name: "RAL9004 (Signal black)", hex: "282828" },
    { code: "RAL9005", name: "RAL9005 (Jet black)", hex: "0A0A0A" },
    { code: "RAL9006", name: "RAL9006 (White aluminium)", hex: "A5A5A5" },
    { code: "RAL9007", name: "RAL9007 (Grey aluminium)", hex: "8F8F8F" },
    { code: "RAL9010", name: "RAL9010 (Pure white)", hex: "FFFFFF" },
    { code: "RAL9011", name: "RAL9011 (Graphite black)", hex: "1C1C1C" },
    { code: "RAL9016", name: "RAL9016 (Traffic white)", hex: "F6F6F6" },
    { code: "RAL9017", name: "RAL9017 (Traffic black)", hex: "1E1E1E" },
    { code: "RAL9018", name: "RAL9018 (Papyrus white)", hex: "D7D7D7" },
    { code: "RAL9022", name: "RAL9022 (Pearl light grey)", hex: "9C9C9C" },
    { code: "RAL9023", name: "RAL9023 (Pearl dark grey)", hex: "828282" },
    { code: "RR11 (S", name: "RR11 (Spruce Green)", hex: "14360E" },
    { code: "RR20 (W", name: "RR20 (White)", hex: "F0ECE4" },
    { code: "RR21 (L", name: "RR21 (Light Grey)", hex: "B7BDBD" },
    { code: "RR22 (G", name: "RR22 (Grey)", hex: "40443E" },
    { code: "RR23 (D", name: "RR23 (Dark Grey)", hex: "40443E" },
    { code: "RR24 (L", name: "RR24 (Light Yellow)", hex: "F3BF51" },
    { code: "RR26 (Y", name: "RR26 (Yellow)", hex: "EAA61F" },
    { code: "RR29 (R", name: "RR29 (Red)", hex: "650503" },
    { code: "RR30 (L", name: "RR30 (Light Brown)", hex: "CEBB80" },
    { code: "RR31 (B", name: "RR31 (Brown)", hex: "6B594D" },
    { code: "RR32 (D", name: "RR32 (Dark Brown)", hex: "332010" },
    { code: "RR33 (B", name: "RR33 (Black)", hex: "090803" },
    { code: "RR34 (L", name: "RR34 (Light Blue)", hex: "73959D" },
    { code: "RR35 (B", name: "RR35 (Blue)", hex: "1B3E5C" },
    { code: "RR36 (L", name: "RR36 (Light Green)", hex: "7FA064" },
    { code: "RR37 (G", name: "RR37 (Green)", hex: "194E14" },
    { code: "RR750 (", name: "RR750 (Tile Red)", hex: "7E2F0C" },
    { code: "RR887 (", name: "RR887 (Chocolate Brown)", hex: "33211F" },
    { code: "LV-ANOD", name: "LV-ANOD (LV-anodisoitu)", hex: "8F8F8F" },
  ],
};

export default RalColors;
