/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint object-shorthand: "error" */
/* eslint-env es6 */
import './parameters.css';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { getCost } from '../../../api';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';
import '../../../cielo/product_form';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import '../../../cielo/styles.css';
import { EXTERNAL_USER } from '../../../constants/userTypes';

const Dialog = () => {
  const { t } = useTranslation('common');
  const { projectData, dispatchProjectData } = useProjectData();
  const { handleUpdate3DModel } = useDataManager();
  const { token, user_id } = projectData.userData;
  const formDialog = new window.product_form.ProductFormDialog(
    process.env.REACT_APP_IFRAME_URL,
    user_id,
    token
  );

  const sidesList = useMemo(() => {
    if (projectData.sides === null) return null;
    return projectData.sides.map((side, index) => ({
      value: side.productId,
      index: index + 1,
      sideId: side.id,
      label: (
        <div className="color-container">
          <div className="side-name">
            {t('parameters.side', { n: index + 1 })}
          </div>
        </div>
      ),
    }));
  }, [projectData.sides, t]);

  const openDialog = (data) => {
    console.log("data to open de dialog ", data)
    const { value, index, sideId } = data;
    formDialog.showProductFormDialog(value,{
      formSubmitSuccess(){
        handleUpdate3DModel();
        dispatchProjectData({ 
          type: 'current_side', 
          payload: {
            productId: value,
            position: index,
            id: sideId
          }
        });
        getCost(projectData.projectId, projectData.userData)
        .then((data) => {
          const { price, priceWithVat } = data;
          const currentPrice =
            projectData.userType === 'distributor' ? price : priceWithVat;
            dispatchProjectData({
              type: 'project_cost',
              payload: parseFloat(currentPrice),
            });
        })
        .catch((error) => {
          console.log("Dialog update Cost Error", error);
        });
      },
      loadError() {
        dispatchProjectData({type: 'remove_user_data'});
        window.location.reload();
      }
    });
  };

  return (
    <div id="dialog-select">
      {projectData.userType !== EXTERNAL_USER && (
        <div>
          <label className="parameter-label">
            {t('parameters.dialog_cielo')}
          </label>
          {projectData.profileColor === null && <LoadingPlaceholder />}
          {projectData.profileColor !== null && (
            <Select
              options={sidesList}
              isSearchable
              onChange={openDialog}
              placeholder={`${t('select')}...`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Dialog;
