import React, { useState } from 'react';
import { BiUser } from "react-icons/bi";
import { IoExitOutline } from "react-icons/io5";
import LoginModal from '../../views/LoginModal';
import useProjectData from '../../providers/ProjectDataProvider/use';
import './login.css';
import { EXTERNAL_USER } from '../../constants/userTypes';

const Login = () => {
  const [needLoginModal, setNeedLoginModal] = useState(false);
  const { projectData, dispatchProjectData } = useProjectData();
  const handleClick = () => {
    if (projectData.userType === EXTERNAL_USER) {
      setNeedLoginModal(true);
    }
  }

  const handleClosingModal = () => {
    setNeedLoginModal(false);
  }

  const handleLogout = () => {
    dispatchProjectData({type: 'user_type', payload: EXTERNAL_USER});
    dispatchProjectData({type: 'remove_user_data'});
    window.location.reload();

  }
  const resolveName = projectData.userType === EXTERNAL_USER ? 'KIRJAUDU SISÄÄN' : projectData.userData.name;
  return (
    <>
      <div className='login-button'>
        <button type='submit' onClick={handleClick}>
          { projectData.userType !== EXTERNAL_USER && (
            <button type='submit' className='exit-button' onClick={handleLogout}>
              <span id="logout-text" className="font-semibold">KIRJAUDU ULOS</span>
              <IoExitOutline style={{marginLeft: '5px', color: 'red'}} />
            </button>
          )}
          <div className='login-user font-semibold'>
            { resolveName } 
            <BiUser style={{marginLeft: '5px'}} />
          </div>
        </button>
      </div>
      { needLoginModal && 
        <LoginModal handleClosingModal={handleClosingModal} />
      }
    </>
  );
};

export default Login;