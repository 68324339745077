/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint object-shorthand: "error" */
/* eslint-env es6 */
import './parameters.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import useProjectData from '../../../providers/ProjectDataProvider/use';
import useDataManager from '../../../providers/DataManagerProvider/use';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import useGlass from '../../../hooks/useGlass';

const Content = ({ type }) => {
  const { t } = useTranslation('common');
  const { projectData } = useProjectData();
  const { handleGlassRequest } = useDataManager();
  const [glassList, chosenGlass] = useGlass(type);

  return (
    <div>
      <label className="parameter-label">{t(`parameters.${type}`)}</label>
      {(projectData.fullGlasses === null || projectData.glass === null) && (
        <LoadingPlaceholder />
      )}
      {projectData.fullGlasses !== null && projectData.glass !== null && (
        <Select
          options={glassList}
          value={chosenGlass}
          name={type}
          isSearchable
          onChange={handleGlassRequest}
          placeholder={`${t('select')}...`}
        />
      )}
    </div>
  );
};

const Glass = ({ type }) => {
  const { projectData } = useProjectData();
  return (
    <div>
      {type === 'glass' && !projectData.dividerCheck && <Content type={type} />}
      {(type.includes('lower') || type.includes('upper')) &&
        projectData.dividerCheck && <Content type={type} />}
    </div>
  );
};

export default Glass;
